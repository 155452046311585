<!-- Another Insurance -->
<div class="form-group section">
    <label class="section-title">{{ 'pageSummaryClaim.anotherInsurance.title' | translate }}</label>
    <div class="section-content">
        <div class="sub-section">
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'anotherInsuranceSection.hasAnotherInsurance' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNoString(claim.getOtherPolicyQuestion())}}
                </div>
            </div>
            <div class="row" *ngIf="claim.getOtherPolicyQuestion() == 'Y'">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.anotherInsurance.companyName' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{claim.getOtherPolicyName()}}
                </div>
            </div>
            <div class="row" *ngIf="claim.getOtherPolicyQuestion() == 'Y' && !this.claim.isHKGorHGI()">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.anotherInsurance.policyNumber' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{claim.getOtherPolicyNumber()}}
                </div>
            </div>
            <div class="row" *ngIf="claim.getOtherPolicyQuestion() == 'Y' && !this.claim.isHKGorHGI()">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.anotherInsurance.submitClaim' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(claim.getOtherPolicyGoingToSubmit())}}
                </div>
            </div>
        </div>
    </div>
</div>
