import {UiOptionsModel} from "./ui-options.model";
export class UIBankService {
  getBankOptions(): UiOptionsModel[] {
    return [
      {"value":"024 | HANG SENG BANK LIMITED","label":"bankList.bl_024" },
      {"value":"004 | HSBC","label":"bankList.bl_004" },
      {"value":"012 | BANK OF CHINA (HONG KONG) LIMITED","label":"bankList.bl_012" },
      {"value":"014 | BANK OF CHINA (HONG KONG) LIMITED","label":"bankList.bl_014" },
      {"value":"019 | BANK OF CHINA (HONG KONG) LIMITED","label":"bankList.bl_019" },
      {"value":"026 | BANK OF CHINA (HONG KONG) LIMITED","label":"bankList.bl_026" },
      {"value":"030 | BANK OF CHINA (HONG KONG) LIMITED","label":"bankList.bl_030" },
      {"value":"031 | BANK OF CHINA (HONG KONG) LIMITED","label":"bankList.bl_031" },
      {"value":"033 | BANK OF CHINA (HONG KONG) LIMITED","label":"bankList.bl_033" },
      {"value":"036 | BANK OF CHINA (HONG KONG) LIMITED","label":"bankList.bl_036" },
      {"value":"064 | BANK OF CHINA (HONG KONG) LIMITED","label":"bankList.bl_064" },
      {"value":"070 | BANK OF CHINA (HONG KONG) LIMITED","label":"bankList.bl_070" },
      {"value":"003 | STANDARD CHARTERED BANK (HONG KONG) LIMITED","label":"bankList.bl_003" },
      {"value":"016 | DBS BANK (HONG KONG) LIMITED","label":"bankList.bl_016" },
      {"value":"015 | THE BANK OF EAST ASIA LIMITED","label":"bankList.bl_015" },
      {"value":"039 | CHIYU BANKING CORPORATION LIMITED","label":"bankList.bl_039" },
      {"value":"041 | CHONG HING BANK LIMITED","label":"bankList.bl_041" },
      {"value":"250 | CITIBANK (HONG KONG) LIMITED","label":"bankList.bl_250" },
      {"value":"040 | DAH SING BANK LTD.","label":"bankList.bl_040" },
      {"value":"018 | CHINA CITIC BANK INTERNATIONAL LIMITED","label":"bankList.bl_018" },
      {"value":"009 | CHINA CONSTRUCTION BANK (ASIA) CORPORATION LIMITED","label":"bankList.bl_009" },
      {"value":"043 | NANYANG COMMERCIAL BANK LIMITED","label":"bankList.bl_043" },
      {"value":"035 | OCBC WING HANG BANK LIMITED","label":"bankList.bl_035" },
      {"value":"028 | PUBLIC BANK (HONG KONG) LIMITED","label":"bankList.bl_028" },
      {"value":"307 | ABN AMRO BANK N.V.","label":"bankList.bl_307" },
      {"value":"222 | AGRICULTURAL BANK OF CHINA LIMITED","label":"bankList.bl_222" },
      {"value":"256 | ALLAHABAD BANK","label":"bankList.bl_256" },
      {"value":"152 | AUSTRALIA AND NEW ZEALAND BANKING GROUP LTD.","label":"bankList.bl_152" },
      {"value":"261 | AXIS BANK LIMITED","label":"bankList.bl_261" },
      {"value":"234 | BANCA MONTE DEI PASCHI DI SIENA SPA","label":"bankList.bl_234" },
      {"value":"147 | BANCO BILBAO VIZCAYA ARGENTARIA, S.A.","label":"bankList.bl_147" },
      {"value":"067 | BANCO DE ORO UNIBANK, INC.","label":"bankList.bl_067" },
      {"value":"267 | BANCO SANTANDER, S.A.","label":"bankList.bl_267" },
      {"value":"049 | BANGKOK BANK PUBLIC COMPANY LIMITED","label":"bankList.bl_049" },
      {"value":"320 | BANK JULIUS BAER AND CO. LTD.","label":"bankList.bl_320" },
      {"value":"055 | BANK OF AMERICA NA","label":"bankList.bl_055" },
      {"value":"259 | BANK OF BARODA","label":"bankList.bl_259" },
      {"value":"338 | BANK OF CHINA LIMITED","label":"bankList.bl_338" },
      {"value":"382 | Bank of Communications (Hong Kong) Limited","label":"bankList.bl_382" },
      {"value":"027 | BANK OF COMMUNICATIONS CO., LTD.","label":"bankList.bl_027" },
      {"value":"058 | BANK OF INDIA","label":"bankList.bl_058" },
      {"value":"086 | BANK OF MONTREAL","label":"bankList.bl_086" },
      {"value":"272 | BANK OF SINGAPORE LIMITED","label":"bankList.bl_272" },
      {"value":"201 | BANK OF TAIWAN","label":"bankList.bl_201" },
      {"value":"278 | BANK SARASIN AND CIE AG","label":"bankList.bl_278" },
      {"value":"241 | BANK SINOPAC","label":"241 | BANK SINOPAC" },
      {"value":"346 | BANQUE PRIVEE EDMOND DE ROTHSCHILD SA","label":"bankList.bl_346" },
      {"value":"074 | BARCLAYS BANK PLC","label":"bankList.bl_074" },
      {"value":"056 | BNP PARIBAS","label":"bankList.bl_056" },
      {"value":"275 | BNP PARIBAS SECURITIES SERVICES","label":"bankList.bl_275" },
      {"value":"350 | BSI LTD","label":"bankList.bl_350" },
      {"value":"339 | CA INDOSUEZ (SWITZERLAND) SA","label":"bankList.bl_339" },
      {"value":"092 | CANADIAN IMPERIAL BANK OF COMMERCE","label":"bankList.bl_092" },
      {"value":"262 | CANARA BANK","label":"bankList.bl_262" },
      {"value":"263 | CATHAY BANK","label":"bankList.bl_263" },
      {"value":"236 | CATHAY UNITED BANK CO LTD","label":"bankList.bl_236" },
      {"value":"206 | CHANG HWA COMMERCIAL BANK LIMITED","label":"bankList.bl_206" },
      {"value":"221 | CHINA CONSTRUCTION BANK CORPORATION","label":"bankList.bl_221" },
      {"value":"276 | CHINA DEVELOPMENT BANK CORPORATION","label":"bankList.bl_276" },
      {"value":"238 | CHINA MERCHANTS BANK CO., LTD.","label":"bankList.bl_238" },
      {"value":"353 | CHINA MINSHENG BANKING CORP LTD","label":"bankList.bl_353" },
      {"value":"383 | CHINA ZHESHANG BANK CO., LTD","label":"bankList.bl_383" },
      {"value":"229 | CHINATRUST COMMERCIAL BANK, LTD","label":"bankList.bl_229" },
      {"value":"006 | CITIBANK N. A.","label":"bankList.bl_006" },
      {"value":"868 | CLS BANK INTERNATIONAL","label":"bankList.bl_868" },
      {"value":"097 | COMMERZBANK AG","label":"bankList.bl_097" },
      {"value":"153 | COMMONWEALTH BANK OF AUSTRALIA","label":"bankList.bl_153" },
      {"value":"183 | COOPERATIEVE RABOBANK U.A.","label":"bankList.bl_183" },
      {"value":"205 | COUTTS AND CO LTD","label":"bankList.bl_205" },
      {"value":"005 | CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK","label":"bankList.bl_005" },
      {"value":"324 | Credit Industriel et Commercial","label":"bankList.bl_324" },
      {"value":"233 | CREDIT SUISSE AG","label":"bankList.bl_233" },
      {"value":"185 | DBS BANK LTD","label":"bankList.bl_185" },
      {"value":"054 | DEUTSCHE BANK AG","label":"bankList.bl_054" },
      {"value":"113 | DZ BANK AG DEUTSCHE ZENTRAL-GENOSSENSCHAFTSBANK","label":"bankList.bl_113" },
      {"value":"243 | E.SUN COMMERCIAL BANK LTD","label":"bankList.bl_243" },
      {"value":"258 | EAST WEST BANK","label":"bankList.bl_258" },
      {"value":"237 | EFG BANK AG","label":"bankList.bl_237" },
      {"value":"227 | ERSTE GROUP BANK AG","label":"bankList.bl_227" },
      {"value":"260 | FAR EASTERN INTERNATIONAL BANK","label":"bankList.bl_260" },
      {"value":"277 | FIRST ABU DHABI BANK PJSC","label":"bankList.bl_277" },
      {"value":"203 | FIRST COMMERCIAL BANK","label":"bankList.bl_203" },
      {"value":"128 | FUBON BANK (HONG KONG) LIMITED","label":"bankList.bl_128" },
      {"value":"308 | HDFC BANK LTD","label":"bankList.bl_308" },
      {"value":"248 | HONG LEONG BANK BERHAD","label":"bankList.bl_248" },
      {"value":"106 | HSBC BANK USA","label":"106 | HSBC BANK USA" },
      {"value":"235 | HSBC PRIVATE BANK (SUISSE) SA","label":"bankList.bl_235" },
      {"value":"198 | HUA NAN COMMERCIAL BANK LTD","label":"bankList.bl_198" },
      {"value":"251 | ICICI BANK LIMITED","label":"bankList.bl_251" },
      {"value":"050 | INDIAN OVERSEAS BANK","label":"bankList.bl_050" },
      {"value":"072 | INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ASIA) LIMITED","label":"bankList.bl_072" },
      {"value":"214 | INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED","label":"bankList.bl_214" },
      {"value":"271 | INDUSTRIAL BANK OF KOREA","label":"bankList.bl_271" },
      {"value":"145 | ING BANK N.V.","label":"bankList.bl_145" },
      {"value":"161 | INTESA SANPAOLO S.P.A","label":"bankList.bl_161" },
      {"value":"007 | JPMORGAN CHASE BANK, N.A., HONG KONG","label":"bankList.bl_007" },
      {"value":"178 | KBC BANK N.V.","label":"bankList.bl_178" },
      {"value":"046 | KEB HANA BANK","label":"bankList.bl_046" },
      {"value":"381 | KOOKMIN BANK","label":"bankList.bl_381" },
      {"value":"264 | LAND BANK OF TAIWAN CO., LTD.","label":"bankList.bl_264" },
      {"value":"342 | LGT BANK","label":"bankList.bl_342" },
      {"value":"313 | LLOYDS TSB BANK PLC","label":"bankList.bl_313" },
      {"value":"344 | MACQUARIE BANK LIMITED","label":"bankList.bl_344" },
      {"value":"063 | MALAYAN BANKING BERHAD","label":"bankList.bl_063" },
      {"value":"242 | MEGA INTERNATIONAL COMMERCIAL BANK CO LTD","label":"bankList.bl_242" },
      {"value":"254 | MELLI BANK PLC","label":"bankList.bl_254" },
      {"value":"138 | MITSUBISHI UFJ TRUST AND BANKING CORPORATION","label":"bankList.bl_138" },
      {"value":"109 | MIZUHO CORPORATE BANK LTD","label":"bankList.bl_109" },
      {"value":"389 | MOX BANK LIMITED","label":"bankList.bl_389" },
      {"value":"150 | NATIONAL AUSTRALIA BANK LIMITED","label":"bankList.bl_150" },
      {"value":"060 | NATIONAL BANK OF PAKISTAN","label":"bankList.bl_060" },
      {"value":"210 | NATIXIS","label":"bankList.bl_210" },
      {"value":"257 | NEWEDGE GROUP","label":"bankList.bl_257" },
      {"value":"274 | O-Bank Co., Ltd","label":"bankList.bl_274" },
      {"value":"022 | OVERSEA-CHINESE BANKING CORPORATION LTD.","label":"bankList.bl_022" },
      {"value":"119 | PHILIPPINE NATIONAL BANK","label":"bankList.bl_119" },
      {"value":"357 | PICTET AND CIE (EUROPE) S.A.","label":"bankList.bl_357" },
      {"value":"066 | PT. BANK NEGARA INDONESIA (PERSERO) TBK.","label":"bankList.bl_066" },
      {"value":"266 | PUNJAB NATIONAL BANK","label":"bankList.bl_266" },
      {"value":"080 | ROYAL BANK OF CANADA","label":"bankList.bl_080" },
      {"value":"025 | SHANGHAI COMMERCIAL BANK LIMITED","label":"bankList.bl_025" },
      {"value":"345 | SHANGHAI PUDONG DEVELOPMENT BANK CO. LTD.","label":"bankList.bl_345" },
      {"value":"273 | SHINHAN BANK","label":"bankList.bl_273" },
      {"value":"316 | SKANDINAVISKA ENSKILDA BANKEN AB","label":"bankList.bl_316" },
      {"value":"081 | SOCIETE GENERALE","label":"bankList.bl_081" },
      {"value":"196 | STANDARD BANK ASIA LIMITED","label":"bankList.bl_196" },
      {"value":"341 | STANDARD BANK PLC.","label":"bankList.bl_341" },
      {"value":"082 | STATE BANK OF INDIA","label":"bankList.bl_082" },
      {"value":"220 | STATE STREET BANK AND TRUST COMPANY","label":"bankList.bl_220" },
      {"value":"065 | SUMITOMO MITSUI BANKING CORPORATION","label":"bankList.bl_065" },
      {"value":"165 | SVENSKA HANDELSBANKEN AB (PUBL)","label":"bankList.bl_165" },
      {"value":"061 | TAI SANG BANK LTD","label":"bankList.bl_061" },
      {"value":"239 | TAIPEI FUBON COMMERCIAL BANK CO","label":"bankList.bl_239" },
      {"value":"245 | TAISHIN INTERNATIONAL BANK CO LTD","label":"bankList.bl_245" },
      {"value":"230 | TAIWAN BUSINESS BANK","label":"bankList.bl_230" },
      {"value":"265 | TAIWAN COOPERATIVE BANK, LTD.","label":"bankList.bl_265" },
      {"value":"337 | TAIWAN SHIN KONG COMMERCIAL BANK CO., LTD.","label":"bankList.bl_337" },
      {"value":"139 | THE BANK OF NEW YORK MELLON","label":"bankList.bl_139" },
      {"value":"076 | THE BANK OF NOVA SCOTIA","label":"bankList.bl_076" },
      {"value":"047 | THE BANK OF TOKYO-MITSUBISHI UFJ, LTD","label":"bankList.bl_047" },
      {"value":"170 | THE CHIBA BANK LTD.","label":"bankList.bl_170" },
      {"value":"202 | THE CHUGOKU BANK LIMITED","label":"bankList.bl_202" },
      {"value":"188 | THE HACHIJUNI BANK, LTD.","label":"bankList.bl_188" },
      {"value":"224 | THE IYO BANK, LTD.,","label":"bankList.bl_224" },
      {"value":"008 | THE ROYAL BANK OF SCOTLAND PLC","label":"bankList.bl_008" },
      {"value":"269 | THE SHANGHAI COMMERCIAL AND SAVINGS BANK, LTD.","label":"bankList.bl_269" },
      {"value":"199 | THE SHIGA BANK, LTD.","label":"bankList.bl_199" },
      {"value":"186 | THE SHIZUOKA BANK LTD.","label":"bankList.bl_186" },
      {"value":"085 | THE TORONTO-DOMINION BANK","label":"bankList.bl_085" },
      {"value":"103 | UBS AG","label":"bankList.bl_103" },
      {"value":"045 | UCO BANK","label":"bankList.bl_045" },
      {"value":"164 | UNICREDIT BANK AG","label":"bankList.bl_164" },
      {"value":"309 | UNION BANCAIRE PRIVÉE, UBP SA","label":"bankList.bl_309" },
      {"value":"268 | UNION BANK OF INDIA","label":"bankList.bl_268" },
      {"value":"071 | UNITED OVERSEAS BANK LTD.","label":"bankList.bl_071" },
      {"value":"180 | WELLS FARGO BANK, N.A.","label":"bankList.bl_180" },
      {"value":"218 | WESTLB AG","label":"bankList.bl_218" },
      {"value":"151 | WESTPAC BANKING CORPORATION","label":"bankList.bl_151" },
      {"value":"020 | WING LUNG BANK LIMITED","label":"bankList.bl_020" },
      {"value":"118 | WOORI BANK","label":"bankList.bl_118" },
      {"value":"378 | Yuanta Commercial Bank Co., Ltd","label":"bankList.bl_378" }
    ]
  }

  getBankOptionsSgp(): UiOptionsModel[] {
    return [
      {"value":"7931 | Australia & New Zealand Banking Group Ltd","label":"bankListSgp.bl_7931"},
      {"value":"7047 | Bangkok Bank Public Company Ltd ","label":"bankListSgp.bl_7047"},
      {"value":"7065 | Bank of America, NA","label":"bankListSgp.bl_7065"},
      {"value":"7083 | Bank of China Limited ","label":"bankListSgp.bl_7083"},
      {"value":"7108 | Bank of India","label":"bankListSgp.bl_7108"},
      {"value":"7418 | BNP Paribas","label":"bankListSgp.bl_7418"},
      {"value":"9353 | Chinatrust Commercial Bank Co Ltd ","label":"bankListSgp.bl_9353"},
      {"value":"7986 | CIMB Bank Berhad ","label":"bankListSgp.bl_7986"},
      {"value":"7214 | Citibank NA","label":"bankListSgp.bl_7214"},
      {"value":"8606 | Commerzbank AG","label":"bankListSgp.bl_8606"},
      {"value":"7135 | Credit Agricole Corporate & Investment Bank","label":"bankListSgp.bl_7135"},
      {"value":"7171 | DBS Bank Ltd","label":"bankListSgp.bl_7171_dbs"},
      {"value":"7764 | First Commercial Bank","label":"bankListSgp.bl_7764"},
      {"value":"7287 | HL Bank (Hong Leong Bank)","label":"bankListSgp.bl_7287"},
      {"value":"9186 | ICICI Bank Ltd","label":"bankListSgp.bl_9186"},
      {"value":"7241 | Indian Bank","label":"bankListSgp.bl_7241"},
      {"value":"7250 | Indian Overseas Bank","label":"bankListSgp.bl_7250"},
      {"value":"8350 | Intesa Sanpaolo SpA","label":"bankListSgp.bl_8350"},
      {"value":"7153 | JPMorgan Chase Bank,N.A.","label":"bankListSgp.bl_7153"},
      {"value":"7490 | Korea Exchange Bank","label":"bankListSgp.bl_7490"},
      {"value":"7302 | Malayan Banking Berhad","label":"bankListSgp.bl_7302"},
      {"value":"7621 | Mizuho Corporate Bank Ltd","label":"bankListSgp.bl_7621"},
      {"value":"8077 | National Australia Bank Ltd","label":"bankListSgp.bl_8077"},
      {"value":"8518 | Nordea Bank Finland Plc","label":"bankListSgp.bl_8518"},
      {"value":"7339 | Oversea-Chinese Banking Corporation Ltd","label":"bankListSgp.bl_7339"},
      {"value":"7056 | P.T. Bank Negara Indonesia (Persero) Tbk / 7056","label":"bankListSgp.bl_7056"},
      {"value":"7366 | RHB Bank Berhad","label":"bankListSgp.bl_7366"},
      {"value":"8527 | Skandinaviska Enskilda Banken AB (publ)","label":"bankListSgp.bl_8527"},
      {"value":"7852 | Societe Generale","label":"bankListSgp.bl_7852"},
      {"value":"9496 | Standard Chartered Bank","label":"bankListSgp.bl_9496"},
      {"value":"7791 | State Bank of India","label":"bankListSgp.bl_7791"},
      {"value":"7472 | Sumitomo Mitsui Banking Corporation","label":"bankListSgp.bl_7472"},
      {"value":"8493 | Svenska Handelsbanken AB (publ)","label":"bankListSgp.bl_8493"},
      {"value":"7092 | The Bank of East Asia Ltd","label":"bankListSgp.bl_7092"},
      {"value":"7126 | The Bank of Tokyo-Mitsubishi UFJ, Ltd","label":"bankListSgp.bl_7126"},
      {"value":"7232 | The Hongkong & Shanghai Banking Corp - Corporate","label":"bankListSgp.bl_7232"},
      {"value":"9548 | The HSBC Bank (Singapore) Ltd. - Personal","label":"bankListSgp.bl_9548"},
      {"value":"7357 | UCO Bank","label":"bankListSgp.bl_7357"},
      {"value":"7117 | Singapore Island Bank Limited","label":"bankListSgp.bl_7117"},
      {"value":"7463 | Deutsche Bank AG","label":"bankListSgp.bl_7463"},
      {"value":"7685 | UBS AG","label":"bankListSgp.bl_7685"},
      {"value":"8712 | Industrial & Commercial Bank of China","label":"bankListSgp.bl_8712"},
      {"value":"7171 | POSB Bank","label":"bankListSgp.bl_7171_posb"},
      {"value":"9201 | Citibank Singapore Ltd","label":"bankListSgp.bl_9201"},
      {"value":"7375 | UOB","label":"bankListSgp.bl_7375"}
    ]
  }

  getBankOptionsMys(): UiOptionsModel[] {
    return [
        {"value":"bankListMys.bl_abna", "label":"bankListMys.bl_abna"},
        {"value":"bankListMys.bl_affi", "label":"bankListMys.bl_affi"},
        {"value":"bankListMys.bl_agob_ab", "label":"bankListMys.bl_agob_ab"},
        {"value":"bankListMys.bl_agob_bpmb", "label":"bankListMys.bl_agob_bpmb"},
        {"value":"bankListMys.bl_aibb", "label":"bankListMys.bl_aibb"},
        {"value":"bankListMys.bl_aisl", "label":"bankListMys.bl_aisl"},
        {"value":"bankListMys.bl_all_ab", "label":"bankListMys.bl_all_ab"},
        {"value":"bankListMys.bl_all_at", "label":"bankListMys.bl_all_at"},
        {"value":"bankListMys.bl_all_akk", "label":"bankListMys.bl_all_akk"},
        {"value":"bankListMys.bl_all_as", "label":"bankListMys.bl_all_as"},
        {"value":"bankListMys.bl_alsr_anl", "label":"bankListMys.bl_alsr_anl"},
        {"value":"bankListMys.bl_alsr_aibb", "label":"bankListMys.bl_alsr_aibb"},
        {"value":"bankListMys.bl_amib", "label":"bankListMys.bl_amib"},
        {"value":"bankListMys.bl_arab", "label":"bankListMys.bl_arab"},
        {"value":"bankListMys.bl_arbk", "label":"bankListMys.bl_arbk"},
        {"value":"bankListMys.bl_bcb", "label":"bankListMys.bl_bcb"},
        {"value":"bankListMys.bl_bimb", "label":"bankListMys.bl_bimb"},
        {"value":"bankListMys.bl_bkch", "label":"bankListMys.bl_bkch"},
        {"value":"bankListMys.bl_bkkb", "label":"bankListMys.bl_bkkb"},
        {"value":"bankListMys.bl_bkrm_bkrb", "label":"bankListMys.bl_bkrm_bkrb"},
        {"value":"bankListMys.bl_bkrm_br", "label":"bankListMys.bl_bkrm_br"},
        {"value":"bankListMys.bl_bmmb", "label":"bankListMys.bl_bmmb"},
        {"value":"bankListMys.bl_bnpa", "label":"bankListMys.bl_bnpa"},
        {"value":"bankListMys.bl_bofa", "label":"bankListMys.bl_bofa"},
        {"value":"bankListMys.bl_botk", "label":"bankListMys.bl_botk"},
        {"value":"bankListMys.bl_bsna", "label":"bankListMys.bl_bsna"},
        {"value":"bankListMys.bl_chas_jcbb", "label":"bankListMys.bl_chas_jcbb"},
        {"value":"bankListMys.bl_chas_jmcbb", "label":"bankListMys.bl_chas_jmcbb"},
        {"value":"bankListMys.bl_cibb", "label":"bankListMys.bl_cibb"},
        {"value":"bankListMys.bl_cimb", "label":"bankListMys.bl_cimb"},
        {"value":"bankListMys.bl_citi_cb", "label":"bankListMys.bl_citi_cb"},
        {"value":"bankListMys.bl_citi_ckl", "label":"bankListMys.bl_citi_ckl"},
        {"value":"bankListMys.bl_citi_ckk", "label":"bankListMys.bl_citi_ckk"},
        {"value":"bankListMys.bl_citi_cco", "label":"bankListMys.bl_citi_cco"},
        {"value":"bankListMys.bl_citi_cp", "label":"bankListMys.bl_citi_cp"},
        {"value":"bankListMys.bl_citi_ci", "label":"bankListMys.bl_citi_ci"},
        {"value":"bankListMys.bl_citi_cjb", "label":"bankListMys.bl_citi_cjb"},
        {"value":"bankListMys.bl_citi_ccop", "label":"bankListMys.bl_citi_ccop"},
        {"value":"bankListMys.bl_citi_ck", "label":"bankListMys.bl_citi_ck"},
        {"value":"bankListMys.bl_citi_cs", "label":"bankListMys.bl_citi_cs"},
        {"value":"bankListMys.bl_citi_csk", "label":"bankListMys.bl_citi_csk"},
        {"value":"bankListMys.bl_citi_cr", "label":"bankListMys.bl_citi_cr"},
        {"value":"bankListMys.bl_citi_cro", "label":"bankListMys.bl_citi_cro"},
        {"value":"bankListMys.bl_citi_ctb", "label":"bankListMys.bl_citi_ctb"},
        {"value":"bankListMys.bl_ctbb", "label":"bankListMys.bl_ctbb"},
        {"value":"bankListMys.bl_deut", "label":"bankListMys.bl_deut"},
        {"value":"bankListMys.bl_dumm", "label":"bankListMys.bl_dumm"},
        {"value":"bankListMys.bl_eobb", "label":"bankListMys.bl_eobb"},
        {"value":"bankListMys.bl_eon", "label":"bankListMys.bl_eon"},
        {"value":"bankListMys.bl_hbmb", "label":"bankListMys.bl_hbmb"},
        {"value":"bankListMys.bl_hlb", "label":"bankListMys.bl_hlb"},
        {"value":"bankListMys.bl_hlbb", "label":"bankListMys.bl_hlbb"},
        {"value":"bankListMys.bl_hlib", "label":"bankListMys.bl_hlib"},
        {"value":"bankListMys.bl_hmab", "label":"bankListMys.bl_hmab"},
        {"value":"bankListMys.bl_hsbc_hb", "label":"bankListMys.bl_hsbc_hb"},
        {"value":"bankListMys.bl_hsbc_hkb", "label":"bankListMys.bl_hsbc_hkb"},
        {"value":"bankListMys.bl_hsbc_hs", "label":"bankListMys.bl_hsbc_hs"},
        {"value":"bankListMys.bl_hsbc_hkl", "label":"bankListMys.bl_hsbc_hkl"},
        {"value":"bankListMys.bl_hsbc_hp", "label":"bankListMys.bl_hsbc_hp"},
        {"value":"bankListMys.bl_hsbc_hi", "label":"bankListMys.bl_hsbc_hi"},
        {"value":"bankListMys.bl_hsbc_hjb", "label":"bankListMys.bl_hsbc_hjb"},
        {"value":"bankListMys.bl_hsbc_hk", "label":"bankListMys.bl_hsbc_hk"},
        {"value":"bankListMys.bl_hsbc_hkc", "label":"bankListMys.bl_hsbc_hkc"},
        {"value":"bankListMys.bl_hsbc_hsb", "label":"bankListMys.bl_hsbc_hsb"},
        {"value":"bankListMys.bl_hsbc_hbr", "label":"bankListMys.bl_hsbc_hbr"},
        {"value":"bankListMys.bl_hsbc_hr", "label":"bankListMys.bl_hsbc_hr"},
        {"value":"bankListMys.bl_hsbc_huc", "label":"bankListMys.bl_hsbc_huc"},
        {"value":"bankListMys.bl_icbk", "label":"bankListMys.bl_icbk"},
        {"value":"bankListMys.bl_ism", "label":"bankListMys.bl_ism"},
        {"value":"bankListMys.bl_kfho", "label":"bankListMys.bl_kfho"},
        {"value":"bankListMys.bl_mbb_mb", "label":"bankListMys.bl_mbb_mb"},
        {"value":"bankListMys.bl_mbb_mt", "label":"bankListMys.bl_mbb_mt"},
        {"value":"bankListMys.bl_mbb_mm", "label":"bankListMys.bl_mbb_mm"},
        {"value":"bankListMys.bl_mbb_mas", "label":"bankListMys.bl_mbb_mas"},
        {"value":"bankListMys.bl_mbb_mkt", "label":"bankListMys.bl_mbb_mkt"},
        {"value":"bankListMys.bl_mbb_mk", "label":"bankListMys.bl_mbb_mk"},
        {"value":"bankListMys.bl_mbb_ms", "label":"bankListMys.bl_mbb_ms"},
        {"value":"bankListMys.bl_mbb_mbm", "label":"bankListMys.bl_mbb_mbm"},
        {"value":"bankListMys.bl_mbb_mbp", "label":"bankListMys.bl_mbb_mbp"},
        {"value":"bankListMys.bl_mbb_mpj", "label":"bankListMys.bl_mbb_mpj"},
        {"value":"bankListMys.bl_mbb_msj", "label":"bankListMys.bl_mbb_msj"},
        {"value":"bankListMys.bl_mbb_mbph", "label":"bankListMys.bl_mbb_mbph"},
        {"value":"bankListMys.bl_mbb_mro", "label":"bankListMys.bl_mbb_mro"},
        {"value":"bankListMys.bl_mbb_mkl", "label":"bankListMys.bl_mbb_mkl"},
        {"value":"bankListMys.bl_mbbe", "label":"bankListMys.bl_mbbe"},
        {"value":"bankListMys.bl_mbis", "label":"bankListMys.bl_mbis"},
        {"value":"bankListMys.bl_mfbb", "label":"bankListMys.bl_mfbb"},
        {"value":"bankListMys.bl_mhcb", "label":"bankListMys.bl_mhcb"},
        {"value":"bankListMys.bl_oabb", "label":"bankListMys.bl_oabb"},
        {"value":"bankListMys.bl_obr", "label":"bankListMys.bl_obr"},
        {"value":"bankListMys.bl_ocbc_ob", "label":"bankListMys.bl_ocbc_ob"},
        {"value":"bankListMys.bl_ocbc_obb", "label":"bankListMys.bl_ocbc_obb"},
        {"value":"bankListMys.bl_ori", "label":"bankListMys.bl_ori"},
        {"value":"bankListMys.bl_oth", "label":"bankListMys.bl_oth"},
        {"value":"bankListMys.bl_pbb", "label":"bankListMys.bl_pbb"},
        {"value":"bankListMys.bl_pbbe", "label":"bankListMys.bl_pbbe"},
        {"value":"bankListMys.bl_phbm", "label":"bankListMys.bl_phbm"},
        {"value":"bankListMys.bl_pibb", "label":"bankListMys.bl_pibb"},
        {"value":"bankListMys.bl_pibe", "label":"bankListMys.bl_pibe"},
        {"value":"bankListMys.bl_rhb", "label":"bankListMys.bl_rhb"},
        {"value":"bankListMys.bl_rhba", "label":"bankListMys.bl_rhba"},
        {"value":"bankListMys.bl_rhbb", "label":"bankListMys.bl_rhbb"},
        {"value":"bankListMys.bl_rjhi", "label":"bankListMys.bl_rjhi"},
        {"value":"bankListMys.bl_scb_scb", "label":"bankListMys.bl_scb_scb"},
        {"value":"bankListMys.bl_scb_sco", "label":"bankListMys.bl_scb_sco"},
        {"value":"bankListMys.bl_scb_skk", "label":"bankListMys.bl_scb_skk"},
        {"value":"bankListMys.bl_scb_ss", "label":"bankListMys.bl_scb_ss"},
        {"value":"bankListMys.bl_scbl", "label":"bankListMys.bl_scbl"},
        {"value":"bankListMys.bl_smbc", "label":"bankListMys.bl_smbc"},
        {"value":"bankListMys.bl_sob", "label":"bankListMys.bl_sob"},
        {"value":"bankListMys.bl_uob", "label":"bankListMys.bl_uob"},
        {"value":"bankListMys.bl_uovb", "label":"bankListMys.bl_uovb"}
    ]
  }

  getBankInstructionSet(country): Map<string, string> {
    let branchInstructionSet: Map<string, string> = new Map();
    if(country === 'hkg' || country === 'hgi'){
      branchInstructionSet.set("382", "bankcomm");
      branchInstructionSet.set("027", "bankcomm");
      branchInstructionSet.set("015", "bea");
      branchInstructionSet.set("012", "bochk");
      branchInstructionSet.set("014", "bochk");
      branchInstructionSet.set("019", "bochk");
      branchInstructionSet.set("026", "bochk");
      branchInstructionSet.set("030", "bochk");
      branchInstructionSet.set("031", "bochk");
      branchInstructionSet.set("033", "bochk");
      branchInstructionSet.set("036", "bochk");
      branchInstructionSet.set("064", "bochk");
      branchInstructionSet.set("070", "bochk");
      branchInstructionSet.set("040", "dahsing");
      branchInstructionSet.set("024", "hangseng");
      branchInstructionSet.set("106", "hsbc");
      branchInstructionSet.set("235", "hsbc");
      branchInstructionSet.set("004", "hsbc");
      branchInstructionSet.set("003", "scb");
    } else if (country === 'sgp') {
      branchInstructionSet.set("7171d", "sgp_dbs");
      branchInstructionSet.set("7171p", "sgp_posb");
      branchInstructionSet.set("7339o", "sgp_ocbc");
      branchInstructionSet.set("7791s", "sgp_sbi");
      branchInstructionSet.set("9496s", "sgp_scb");
      branchInstructionSet.set("9548t", "sgp_hsbc");
      branchInstructionSet.set("7232t", "sgp_hsbc");
      branchInstructionSet.set("7375u", "sgp_uob");
    }
    return branchInstructionSet;
  }

  getBranchInstructionCode(bankCode, country): string {
    return this.getBankInstructionSet(country).get(bankCode);
  }
}
