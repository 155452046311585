import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimService } from 'src/app/services/claim.service';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { ClaimTypes } from 'src/app/model/claim-type.model';
import { ClaimPersonalAccident } from 'src/app/model/claim-personal-accident.model';
import { UtilitiesService } from 'src/app/utilities/utilities.service';
import { OutpatientConsultDetails } from 'src/app/model/personal-accident/outpatient-consult-details.model';
import { HospitalExpensesComponent } from '../../claim-types/accidental-medical-expenses/hospital/hospital-expenses.component';
import { OutpatientConsultComponent } from '../../claim-types/accidental-medical-expenses/outpatient-consult/outpatient-consult.component';
import { PaUploadDocComponent } from '../../upload-docs/pa-upload-doc.component';

declare const $: any;

@Component({
    selector: 'app-pa-ame',
    templateUrl: './pa-ame.component.html',
    styleUrls: ['./pa-claim-types.component.css']
})
export class PaAmeComponent extends ClaimsBaseComponent implements OnInit {
    showTotalBox = false;
    transactionInfo: TransactionInfo;
    claimPA: ClaimPersonalAccident;
    outpatientConsultation: OutpatientConsultDetails;
    currency: string;

    @ViewChild('hospitalExpenses',  {static: false}) hospitalExpenses: HospitalExpensesComponent;
    @ViewChild('outpatientConsultations', {static: false}) outpatientConsultations: OutpatientConsultComponent;
    @ViewChild('uploadDocs', {static: false}) uploadDocs: PaUploadDocComponent;
    
    constructor(private transactionInfoService: TransactionInfoService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,) {

        super(injector);
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES]);
        this.claim = this.claimService.getClaim();
        this.claimPA = this.claim.getClaimPersonalAccident();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
    }

    ngOnInit() {
        this.pushGAPageView();
        this.currency = UtilitiesService.getCountryCurrency(this.claim.getCountry());

        if (UtilitiesService.isNullOrUndefined(this.claimPA.getAccidentalMedicalExpenses().getWasAdmitted())) {
            this.claimPA.getAccidentalMedicalExpenses().setWasAdmitted(false);
        }

        setTimeout(() => {
            this.showSvgPath();
        }, 10);
    }

    goToNext() {
        if (this.validateForm()) {
            this.uploadDocs.goToNext();
        }
    }

    validateForm(): boolean {
        let hospitalExpenseFormValid = this.hospitalExpenses.validateForm();
        let wasAdmitted = this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getWasAdmitted();
        if (wasAdmitted) {
            let outpatientConsultFormValid = this.outpatientConsultations.validateForm();
            return hospitalExpenseFormValid && outpatientConsultFormValid;
        } else {
            return hospitalExpenseFormValid;
        }
    }

    back() {
        this.router.navigate(["/claimform/claimDetail/selectClaim"], {
            relativeTo: this.activatedRoute
        });
    }

    showSvgPath() {
        $('img.svg,.icon').each(function () {
            let $img = $(this);
            let imgID = $img.attr('id');
            let imgClass = $img.attr('class');
            let imgURL = $img.attr('src');

            $.get(imgURL, function (data) {
                // Get the SVG tag, ignore the rest
                let $svg = $(data).find('svg');

                // Add replaced image's ID to the new SVG
                if (typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if (typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass + ' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Check if the viewport is set, else we gonna set it if we can.
                if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                    $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
                }

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGAPageView() {
        let pageStep = this.transactionInfo.getGaPageStep() + ' - Accidental Medical Expenses';
        let vPath = this.transactionInfo.getGaVPath() + '/pa-ame';
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': pageStep,
            'vPath': vPath,
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 6}
                }
            }
        });
    }
}
