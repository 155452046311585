<ng-container *ngIf="!claim.isHKGorHGI(); else accidentDetailsHkg">
<div class="section form-group">
    <label class="section-title">{{'paClaim.accidentDetails.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'paClaim.accidentDetails.occupation' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{occupation}}
                </div>
            </div>

            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'paClaim.accidentDetails.jobTitle' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claimPA.getAccidentDetails().getJobTitle()}}
                </div>
            </div>

            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'paClaim.accidentDetails.accidentDate' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDateTime(claimPA.getAccidentDetails().getAccidentDate())}}
                </div>
            </div>

            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'paClaim.accidentDetails.countryIncidentOccur' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claimPA.getAccidentDetails().getCountryIncidentOccurDesc()}}
                </div>
            </div>

            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'paClaim.accidentDetails.accidentAddress' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claimPA.getAccidentDetails().getAccidentAddress()}}
                </div>
            </div>

            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'paClaim.accidentDetails.accidentDescription' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claimPA.getAccidentDetails().getAccidentDescription()}}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section form-group">
    <label class="section-title">{{'paClaim.injuryDetails.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'paClaim.injuryDetails.hasBeenInjured' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(claimPA.getInjuryDetails().getHasBeenInjured())}}
                </div>
            </div>

            <ng-container *ngIf="claimPA.getInjuryDetails().getHasBeenInjured()">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.injuryDetails.labelParts' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{injuredParts}}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.injuryDetails.natureInjury.label' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{natureInjury}}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.injuryDetails.hasEncounteredSimilarIncident' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(claimPA.getInjuryDetails().getHasEncounteredSimilarIncident())}}
                    </div>
                </div>

                <div *ngIf="this.claimPA.getInjuryDetails().getHasEncounteredSimilarIncident()" class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.injuryDetails.placeOfIncident' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{claimPA.getInjuryDetails().getPlaceOfIncident()}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
</ng-container>

<ng-template #accidentDetailsHkg>
    <div class="section form-group">
        <label class="section-title">{{'paClaim.accidentDetails.title' | translate}}</label>
        <div class="section-content">
            <div class="sub-section">
                <label>{{'paClaim.accidentDetails.subTitle' | translate}}</label>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.accidentDetails.occupation' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{occupation}}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.accidentDetails.accidentDate' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDateTime(claimPA.getAccidentDetails().getAccidentDate())}}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.accidentDetails.accidentDescription2' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{claimPA.getAccidentDetails().getAccidentDescription()}}
                    </div>
                </div>
            </div>

            <div class="sub-section">
                <label>{{'paClaim.injuryDetails.title' | translate}}</label>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.injuryDetails.labelParts' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{injuredParts}}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.injuryDetails.hasEncounteredSimilarIncident' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(claimPA.getInjuryDetails().getHasEncounteredSimilarIncident())}}
                    </div>
                </div>

                <div *ngIf="this.claimPA.getInjuryDetails().getHasEncounteredSimilarIncident()" class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.injuryDetails.placeOfIncident' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{claimPA.getInjuryDetails().getPlaceOfIncident()}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>