import {DomesticInfoPlan} from "../model/domestic-helper/domestic-info-plan.model";
import {PlanInfoService} from "./plan-info.service";
import {MotorCoverInfo} from '../model/motor/motor-cover-info.model';
import {ClaimTypes} from '../model/claim-type.model';
import {Injectable} from '@angular/core';
import { UtilitiesService } from "../utilities/utilities.service";
import {InsuredPerson} from '../model/insured-person.model';
import {ClaimTypeItem} from '../model/claim-type-item.model';
import {Claim} from '../model/claim.model';

@Injectable()
export class PlanHelperService {

    constructor(private planInfoService: PlanInfoService) {}

    getAvailableDomesticClaimType(planCode: string, catalogCode: string, country: string): string[] {

        // get plan info based on country, plan code and catalogue code
        let domesticPlanDetails = this.getDomesticPlanDetails(planCode, catalogCode, country);
        let availableClaimType = this.getDomesticClaimTypesOnPlan(domesticPlanDetails);
        return availableClaimType;
    }

    getDomesticPlanDetails(planCode: string, catalogCodeInput: string, country: string):  DomesticInfoPlan {

        let domesticInfoPlanList = this.planInfoService.getDomesticPlanInfoByCountry(country);
        let catalogueCode = country.toLowerCase() == "hgi" ? catalogCodeInput : null;

        let domesticInfoPlan = new DomesticInfoPlan();

        if(domesticInfoPlanList.length > 0) {
            domesticInfoPlanList.forEach(function (domesticPlanItem) {
                if (!UtilitiesService.isNullOrUndefined(planCode) && !UtilitiesService.isNullOrUndefined(catalogueCode) && domesticPlanItem.planCode == planCode
                    && domesticPlanItem.catalogCode == catalogueCode) {
                    domesticInfoPlan = domesticPlanItem;
                } else if (!UtilitiesService.isNullOrUndefined(planCode) && UtilitiesService.isNullOrUndefined(catalogueCode) && domesticPlanItem.planCode == planCode) {
                    domesticInfoPlan = domesticPlanItem;
                }
            });
        }
        //console.log("getDomesticPlanDetails(): domesticInfoPlan.planCode:: " + domesticInfoPlan.planCode + 'catalogCodeInput:' + catalogCodeInput);
        return domesticInfoPlan;
    }


    getDomesticClaimTypesOnPlan(domesticInfoPlan : DomesticInfoPlan): string[] {
        let availableClaimTypeList: string[] = [];

        //check domestic claim type is available on the policy plan
        if (!UtilitiesService.isNullOrUndefined(domesticInfoPlan)) {
            if (domesticInfoPlan.clinicalExpenses == "Yes") {
                availableClaimTypeList.push("CLAIM_TYPE_DMH_CLINICAL");
            }
            if (domesticInfoPlan.dentalExpenses == "Yes") {
                availableClaimTypeList.push("CLAIM_TYPE_DMH_DENTAL");
            }
            if (domesticInfoPlan.hospitalizationOfHelper == "Yes") {
                availableClaimTypeList.push("CLAIM_TYPE_DMH_HOSPITAL");
            }
            if (domesticInfoPlan.deathOrPermanentDisability == "Yes") {
                availableClaimTypeList.push("CLAIM_TYPE_DMH_DEATH");
            }
            if (domesticInfoPlan.helpersLiability == "Yes") {
                availableClaimTypeList.push("CLAIM_TYPE_DMH_HELP_LIABILITY");
            }
            if (domesticInfoPlan.employeeCompensation == "Yes") {
                availableClaimTypeList.push("CLAIM_TYPE_DMH_EMP_LIABILITY");
            }
            if (domesticInfoPlan.dishonestyOfHelperLoss == "Yes") {
                availableClaimTypeList.push("CLAIM_TYPE_DMH_DISHONESTY");
            }
            if (domesticInfoPlan.repatriation == "Yes") {
                availableClaimTypeList.push("CLAIM_TYPE_DMH_REPATRIATION");
            }
            if (domesticInfoPlan.otherClaims == "Yes") {
                availableClaimTypeList.push("CLAIM_TYPE_DMH_OTHERS");
            }
        }
        return availableClaimTypeList;
    }

    getEligibleMotorClaimTypes(isPolicyHolder: boolean, contractType: string, riskType: string, coverType: string): string[] {
        let motorCoverInfo = new MotorCoverInfo();
        let eligibleClaimTypes: string[] = [];
        let coverPlanInfoList = this.planInfoService.getMotorCoverInfoList();
        if (coverPlanInfoList.length > 0) {
            motorCoverInfo = coverPlanInfoList.find(coverInfo => coverInfo.isPolicyHolder == isPolicyHolder && coverInfo.contractType == contractType &&
                coverInfo.riskType == riskType && coverInfo.coverType == coverType);
            if (!UtilitiesService.isNullOrUndefined(motorCoverInfo)) {
                eligibleClaimTypes = this.getApplicableMotorClaimTypeList(motorCoverInfo);
            }
        }
        return eligibleClaimTypes;
    }

    getApplicableMotorClaimTypeList(motorCoverInfo : MotorCoverInfo) {
        let availableClaimTypeList: string[] = [];

        if (!UtilitiesService.isNullOrUndefined(motorCoverInfo)) {
            if (motorCoverInfo.windscreen == "Yes") {
                availableClaimTypeList.push(ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_WINDSCREEN]);
            }
            if (motorCoverInfo.collision == "Yes") {
                availableClaimTypeList.push(ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_COLLISION]);
            }
            if (motorCoverInfo.theft == "Yes") {
                availableClaimTypeList.push(ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_THEFT]);
            }
        }
        return availableClaimTypeList;
    }

    getEligiblePAClaimTypesByInsured(product: string, claimTypes: ClaimTypeItem[], insured: InsuredPerson,
                                     claim: Claim): ClaimTypeItem[] {
        let insuredCoverDetails = insured.getCoverDetailsList();
        let self = this;

        if (product === 'PAM') {
            self.showEligibleClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH], claimTypes);
        } else {
            if (insuredCoverDetails) {
                let claimPersonalAccident = claim.getClaimPersonalAccident();
                let hasPanM2Cover, hasPanM3Cover = false;
                let self = this;

                insuredCoverDetails.forEach(function (coverDetails) {
                    let paCoverage = self.planInfoService.getPACoverageList().find(cover => cover.country === claim.getCountry() &&
                        cover.product === product && cover.coverCode === coverDetails.getCoverCode());
                    if (!UtilitiesService.isNullOrUndefined(paCoverage)) {
                        if (paCoverage.claimType === ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES]) {
                            self.showEligibleClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES], claimTypes);
                            claimPersonalAccident.getAccidentalMedicalExpenses().setSumInsured(coverDetails.getSumInsured());
                            // additional rules for PAN - M2
                            if (paCoverage.coverCode === 'M2') {
                                hasPanM2Cover = true;
                                if (insuredCoverDetails.length === 1) {
                                    claimTypes[0]['show'] = false;
                                }
                            } else if (paCoverage.coverCode === 'M3') {
                                hasPanM3Cover = true;
                            }
                        } else if (paCoverage.claimType === ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH]) {
                            self.showEligibleClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH], claimTypes);
                            claimPersonalAccident.getHospitalCash().setSumInsured(coverDetails.getSumInsured());
                        } else {
                            self.showEligibleClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS], claimTypes);
                        }

                    }
                });

                // additional rule for M2 with M3 coverage
                if (hasPanM2Cover && hasPanM3Cover && insuredCoverDetails.length >= 3) {
                    let m3SumInsured = insuredCoverDetails.find(covers => covers.getCoverCode() === 'M3').getSumInsured();
                    claimPersonalAccident.getAccidentalMedicalExpenses().setSumInsured(m3SumInsured);
                }
            }

            //Do not show Hospital Cash for HKG/HGI if has AME
            if (claim.isHKGorHGI()) {
                this.hideHospitalCashClaimType(claimTypes);
            }
        }

        this.showEligibleClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS], claimTypes);
        return claimTypes;
    }

    showEligibleClaimType(claimTypeCode: string, claimTypeItems: ClaimTypeItem[]) {
        for (const claimTypeItem of claimTypeItems) {
            if (claimTypeItem.claimTypeCode === claimTypeCode) {
                claimTypeItem.show = true;
                break;
            }
        }
    }

    hideHospitalCashClaimType(claimTypes) {
        let ame = claimTypes.find(item => item.claimTypeCode === ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES] 
                                        && item.show === true)
        if (ame) {
            claimTypes.forEach(item => {
                if (item.claimTypeCode === ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH]) {
                    item.show = false;
                }
            });
        }
    }

}