import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {HomepageComponent} from './homepage/homepage.component';
import {ClaimFormComponent} from './claim-pages/claim-form.component';
import {GetStartedComponent} from './claim-pages/get-started/get-started.component';
import {SelectInsuredComponent} from './claim-pages/select-insured/select-insured.component';
import {ClaimDetailComponent} from './claim-pages/claim-detail/claim-detail.component';
import {SelectClaimComponent} from './claim-pages/claim-detail/select-claim/select-claim.component';
import {DetailOfTravelDelayComponent} from './claim-pages/claim-types-travel/travel-delay/detail-of-travel-delay/detail-of-travel-delay.component';
import {ReasonOfTravelDelayComponent} from './claim-pages/claim-types-travel/travel-delay/reason-of-travel-delay/reason-of-travel-delay.component';
import {TravelExtralCostComponent} from './claim-pages/claim-types-travel/travel-delay/travel-extral-cost/travel-extral-cost.component';
import {TravelSupportDocComponent} from './claim-pages/claim-types-travel/travel-delay/travel-support-doc/travel-support-doc.component';
import {DetailOfLossComponent} from './claim-pages/claim-types-travel/lost-personal-item/detail-of-loss/detail-of-loss.component';
import {ReportTheLossComponent} from './claim-pages/claim-types-travel/lost-personal-item/report-the-loss/report-the-loss.component';
import {LossSupportDocComponent} from './claim-pages/claim-types-travel/lost-personal-item/support-doc/loss-support-doc.component';
import {TravelDelayComponent} from './claim-pages/claim-types-travel/travel-delay/travel-delay.component';
import {LostPersonalItemComponent} from './claim-pages/claim-types-travel/lost-personal-item/lost-personal-item.component';
import {UploadDocumentComponent} from './claim-pages/claim-types-travel/travel-delay/travel-support-doc/upload-document/upload-document.component';
import {DetailsOfLostItemsComponent} from './claim-pages/claim-types-travel/lost-personal-item/lost-items/details-of-lost-items.component';
import {LossUploadDocumentComponent} from './claim-pages/claim-types-travel/lost-personal-item/support-doc/upload-document/loss-upload-document.component';
import {DamagePersonalItemComponent} from './claim-pages/claim-types-travel/damage-personal-item/damage-personal-item.component';
import {DetailOfDamageComponent} from './claim-pages/claim-types-travel/damage-personal-item/detail-of-damage/detail-of-damage.component';
import {DetailOfDamageItemsComponent} from './claim-pages/claim-types-travel/damage-personal-item/detail-of-damage-items/detail-of-damage-items.component';
import {ReportTheDamageComponent} from './claim-pages/claim-types-travel/damage-personal-item/report-the-damage/report-the-damage.component';
import {DamageSupportDocComponent} from './claim-pages/claim-types-travel/damage-personal-item/damage-support-doc/damage-support-doc.component';
import {DamageUploadDocumentComponent} from './claim-pages/claim-types-travel/damage-personal-item/damage-support-doc/damage-upload-document/damage-upload-document.component';
import {DetailOfIllnessComponent} from './claim-pages/claim-types-travel/visited-doctor/detail-of-illness/detail-of-illness.component';
import {DetailsOfOverseaDoctorVisitComponent} from './claim-pages/claim-types-travel/visited-doctor/oversea-doctor-visit/details-of-oversea-doctor-visit.component';
import {FollowUpTreatmentComponent} from './claim-pages/claim-types-travel/visited-doctor/follow-up-treatment/follow-up-treatment.component';
import {MedicalBillsComponent} from './claim-pages/claim-types-travel/visited-doctor/medical-bills/medical-bills.component';
import {BaggageDelayComponent} from './claim-pages/claim-types-travel/baggage-delay/baggage-delay.component';
import {DetailsOfBaggageDelayComponent} from './claim-pages/claim-types-travel/baggage-delay/details/details-of-baggage-delay.component';
import {EmergencyPurchasedItemComponent} from './claim-pages/claim-types-travel/baggage-delay/emergency-items/emergency-purchased-item.component';
import {BaggageDelaySupportDocComponent} from './claim-pages/claim-types-travel/baggage-delay/support-doc/baggage-delay-support-doc.component';
import {BaggageDelayUploadDocumentComponent} from './claim-pages/claim-types-travel/baggage-delay/support-doc/baggage-delay-upload-document/baggage-delay-upload-document.component';
import {OtherClaimComponent} from './claim-pages/claim-types-travel/other-claim/other-claim.component';
import {DetailsOfIncidentComponent} from './claim-pages/claim-types-travel/other-claim/details-of-incident/details-of-incident.component';
import {DescOfIncidentComponent} from './claim-pages/claim-types-travel/other-claim/desc-of-incident/desc-of-incident.component';
import {OtherClaimSupportDocComponent} from './claim-pages/claim-types-travel/other-claim/other-claim-support-doc/other-claim-support-doc.component';
import {OtherClaimUploadDocumentComponent} from './claim-pages/claim-types-travel/other-claim/other-claim-support-doc/other-claim-upload-document/other-claim-upload-document.component';
import {PaymentContactComponent} from './claim-pages/payment-contact/payment-contact.component';
import {BankAccountComponent} from './claim-pages/payment-contact/bank-account/bank-account.component';
import {ProvideContactInfoComponent} from './claim-pages/provide-contact-info/provide-contact-info.component';
import {SendChequeComponent} from './claim-pages/payment-contact/send-cheque/send-cheque.component';
import {MoreClaimTypeComponent} from './claim-pages/more-claim-type/more-claim-type.component';
import {SummaryOfClaimComponent} from './claim-pages/summary-of-claim/summary-of-claim.component';
import {CompleteComponent} from './claim-pages/complete/complete.component';
import {ConfirmTravelPeriodComponent} from './claim-pages/confirm-travel-period/confirm-travel-period.component';
import {VisitedDoctorUploadDocumentComponent} from './claim-pages/claim-types-travel/visited-doctor/support-doc/visited-doctor-upload-document/visited-doctor-upload-document.component';
import {VisitedDoctorSupportDocComponent} from './claim-pages/claim-types-travel/visited-doctor/support-doc/visited-doctor-support-doc.component';
import {VisitedDoctorComponent} from './claim-pages/claim-types-travel/visited-doctor/visited-doctor.component';
import {StayInHospitalComponent} from './claim-pages/claim-types-travel/stay-in-hospital/stay-in-hospital.component';
import {DetailOfHospitalIllnessComponent} from './claim-pages/claim-types-travel/stay-in-hospital/hospital-illness/detail-of-hospital-illness.component';
import {DetailOfOverseaHospitalStayComponent} from './claim-pages/claim-types-travel/stay-in-hospital/detail-of-oversea-hospital-stay/detail-of-oversea-hospital-stay.component';
import {HospitalFollowUpTreatmentComponent} from './claim-pages/claim-types-travel/stay-in-hospital/follow-up-treatment/hospital-follow-up-treatment.component';
import {HospitalMedicalBillsComponent} from './claim-pages/claim-types-travel/stay-in-hospital/medical-bills/hospital-medical-bills.component';
import {HospitalSupportDocComponent} from './claim-pages/claim-types-travel/stay-in-hospital/support-doc/hospital-support-doc.component';
import {HospitalUploadDocumentComponent} from './claim-pages/claim-types-travel/stay-in-hospital/support-doc/upload-document/hospital-upload-document.component';
import {PermissionGuard} from './guard/permission.guard';
import {TripCancellationComponent} from './claim-pages/claim-types-travel/trip-cancellation/trip-cancellation.component';
import {DetailOfCancelTripComponent} from './claim-pages/claim-types-travel/trip-cancellation/detail-of-cancel-trip/detail-of-cancel-trip.component';
import {LossCausedByCancellationComponent} from './claim-pages/claim-types-travel/trip-cancellation/loss-caused-by-cancellation/loss-caused-by-cancellation.component';
import {CancelSupportDocComponent} from './claim-pages/claim-types-travel/trip-cancellation/cancel-support-doc/cancel-support-doc.component';
import {CancelUploadDocumentComponent} from './claim-pages/claim-types-travel/trip-cancellation/cancel-support-doc/cancel-upload-document/cancel-upload-document.component';
import {TripCurtailmentComponent} from './claim-pages/claim-types-travel/trip-curtailment/trip-curtailment.component';
import {DetailOfTripCurtailmentComponent} from './claim-pages/claim-types-travel/trip-curtailment/detail-of-trip-curtailment/detail-of-trip-curtailment.component';
import {LossCausedByCurtailmentComponent} from './claim-pages/claim-types-travel/trip-curtailment/loss-caused-by-curtailment/loss-caused-by-curtailment.component';
import {CurtailmentSupportDocComponent} from './claim-pages/claim-types-travel/trip-curtailment/curtailment-support-doc/curtailment-support-doc.component';
import {CurtailmentUploadDocumentComponent} from './claim-pages/claim-types-travel/trip-curtailment/curtailment-support-doc/curtailment-upload-document/curtailment-upload-document.component';
import {ExtraCostComponent} from './claim-pages/claim-types-travel/trip-curtailment/extra-cost/extra-cost.component';
import {RentalVehicleComponent} from './claim-pages/claim-types-travel/rental-vehicle/rental-vehicle.component';
import {DetailOfIncidentComponent} from './claim-pages/claim-types-travel/rental-vehicle/detail-of-incident/detail-of-incident.component';
import {VehicleSupportDocComponent} from './claim-pages/claim-types-travel/rental-vehicle/vehicle-support-doc/vehicle-support-doc.component';
import {VehicleUploadDocumentComponent} from './claim-pages/claim-types-travel/rental-vehicle/vehicle-support-doc/vehicle-upload-document/vehicle-upload-document.component';
import {TravelReroutingComponent} from './claim-pages/claim-types-travel/travel-rerouting/travel-rerouting.component';
import {ReroutingDetailComponent} from './claim-pages/claim-types-travel/travel-rerouting/rerouting-detail/rerouting-detail.component';
import {ReroutingUploadDocumentComponent} from './claim-pages/claim-types-travel/travel-rerouting/rerouting-support-doc/rerouting-upload-document/rerouting-upload-document.component';
import {MissEventComponent} from './claim-pages/claim-types-travel/miss-event/miss-event.component';
import {DetailOfMissEventComponent} from './claim-pages/claim-types-travel/miss-event/detail-of-miss-event/detail-of-miss-event.component';
import {LossOfMissEventComponent} from './claim-pages/claim-types-travel/miss-event/loss-of-miss-event/loss-of-miss-event.component';
import {MissEventSuppoortDocComponent} from './claim-pages/claim-types-travel/miss-event/miss-event-suppoort-doc/miss-event-suppoort-doc.component';
import {MissEventUploadDocumentComponent} from './claim-pages/claim-types-travel/miss-event/miss-event-suppoort-doc/miss-event-upload-document/miss-event-upload-document.component';
import {MissConnectComponent} from './claim-pages/claim-types-travel/miss-connect/miss-connect.component';
import {DetailOfMissConnectComponent} from './claim-pages/claim-types-travel/miss-connect/detail-of-miss-connect/detail-of-miss-connect.component';
import {LossOfMissConnectComponent} from './claim-pages/claim-types-travel/miss-connect/loss-of-miss-connect/loss-of-miss-connect.component';
import {MissConnectSupportDocComponent} from './claim-pages/claim-types-travel/miss-connect/miss-connect-support-doc/miss-connect-support-doc.component';
import {MissConnectUploaDocumentComponent} from './claim-pages/claim-types-travel/miss-connect/miss-connect-support-doc/miss-connect-uploa-document/miss-connect-uploa-document.component';
import {PersonalAccidentComponent} from './claim-pages/claim-types-travel/personal-accident/personal-accident.component';
import {DetailOfAccidentComponent} from './claim-pages/claim-types-travel/personal-accident/detail-of-accident/detail-of-accident.component';
import {DetailOfOverseaHospitalComponent} from './claim-pages/claim-types-travel/personal-accident/detail-of-oversea-hospital/detail-of-oversea-hospital.component';
import {AccidentSupportDocComponent} from './claim-pages/claim-types-travel/personal-accident/accident-support-doc/accident-support-doc.component';
import {AccidentUploadDocumentComponent} from './claim-pages/claim-types-travel/personal-accident/accident-support-doc/accident-upload-document/accident-upload-document.component';
import {PersonalLiabilityComponent} from './claim-pages/claim-types-travel/personal-liability/personal-liability.component';
import {AccidentMedicalBillsComponent} from './claim-pages/claim-types-travel/personal-accident/accident-medical-bills/accident-medical-bills.component';
import {AccidentFollowUpTreamentComponent} from './claim-pages/claim-types-travel/personal-accident/accident-follow-up-treament/accident-follow-up-treament.component';
import {LiablitySupportDocComponent} from './claim-pages/claim-types-travel/personal-liability/support-doc/liablity-support-doc.component';
import {LiablityUploadDocumentComponent} from './claim-pages/claim-types-travel/personal-liability/support-doc/upload-document/liablity-upload-document.component';
import {LiabilityDetailOfIncidentComponent} from './claim-pages/claim-types-travel/personal-liability/detail-of-incident/liability-detail-of-incident.component';
import {LiabilityDescOfIncidentComponent} from './claim-pages/claim-types-travel/personal-liability/desc-of-incident/liability-desc-of-incident.component';
import {ClaimBootComponent} from "./boot/claim-boot.component";
import {AnotherInsuranceComponent} from "./claim-pages/another-insurance/another-insurance.component";
import {MeansOfTransportationComponent} from "./claim-pages/claim-types-travel/travel-rerouting/means-of-transportation/means-of-transportation.component";
import {ClinicalExpensesComponent} from "./claim-pages/claim-types-domestic-helper/clinical-expenses/clinical-expenses.component";
import {ClinicalExpensesDetailComponent} from "./claim-pages/claim-types-domestic-helper/clinical-expenses/clinical-expense-detail/clinical-expenses-detail.component";
import {ClinicalExpensesConditionsComponent} from "./claim-pages/claim-types-domestic-helper/clinical-expenses/clinical-expense-conditions/clinical-expenses-conditions.component";
import {ClinicalExpensesVisitComponent} from "./claim-pages/claim-types-domestic-helper/clinical-expenses/clinical-expense-visit/clinical-expenses-visit.component";
import {ClinicalExpenseUploadDocumentComponent} from "./claim-pages/claim-types-domestic-helper/clinical-expenses/clinic-expense-support-doc/clinical-expense-upload-document.component";
import { DentalExpensesComponent } from './claim-pages/claim-types-domestic-helper/dental-expenses/dental-expenses.component';
import { DetailsOfInjuryIllnessComponent } from './claim-pages/claim-types-domestic-helper/dental-expenses/details-of-injury-illness/details-of-injury-illness.component';
import { DentalExpenseIncurred } from './claim-pages/claim-types-domestic-helper/dental-expenses/dental-expenses-incurred/dental-expenses-incurred.component';
import { DentalExpenseUploadDocumentComponent } from './claim-pages/claim-types-domestic-helper/dental-expenses/dental-expense-support-doc/dental-expense-upload-document.component';
import {HelpersLiabilityComponent} from "./claim-pages/claim-types-domestic-helper/helpers-liability/helpers-liability.component";
import {HelpersLiabilityDetailComponent} from "./claim-pages/claim-types-domestic-helper/helpers-liability/helpers-liability-detail/helpers-liability-detail.component";
import {HelpersLiabilityIncidentComponent} from "./claim-pages/claim-types-domestic-helper/helpers-liability/helpers-liability-incident/helpers-liability-incident.component";
import {HelpersLiabilityReportingPolicyComponent} from "./claim-pages/claim-types-domestic-helper/helpers-liability/helpers-liability-reporting-policy/helpers-liability-reporting-policy.component";
import {HelpersLiabilityUploadDocumentComponent} from "./claim-pages/claim-types-domestic-helper/helpers-liability/helpers-liabiity-support-doc/helpers-liability-upload-document.component";
import {DishonestyHelperComponent} from "./claim-pages/claim-types-domestic-helper/dishonesty-helper/dishonesty-helper.component";
import {DishonestyHelperDetailComponent} from "./claim-pages/claim-types-domestic-helper/dishonesty-helper/dishonesty-helper-detail/dishonesty-helper-detail.component";
import {DishonestyHelperUploadDocumentComponent} from "./claim-pages/claim-types-domestic-helper/dishonesty-helper/dishonesty-helper-support-doc/dishonesty-helper-upload-document.component";
import {EmployeeCompensationComponent} from "./claim-pages/claim-types-domestic-helper/employee-compensation/employee-compensation.component";
import {EmployeeCompensationDetailComponent} from "./claim-pages/claim-types-domestic-helper/employee-compensation/employee-compensation-detail/employee-compensation-detail.component";
import {EmployeeCompensationUploadDocumentComponent} from "./claim-pages/claim-types-domestic-helper/employee-compensation/employee-compensation-support-doc/employee-compensation-upload-document.component";
import {OtherClaimDMHComponent} from "./claim-pages/claim-types-domestic-helper/other-claim/other-claim.component";
import {OtherClaimDMHDetailComponent} from "./claim-pages/claim-types-domestic-helper/other-claim/other-claim-detail/other-claim-detail.component";
import {OtherClaimDMHUploadDocumentComponent} from "./claim-pages/claim-types-domestic-helper/other-claim/other-claim-support-doc/other-claim-upload-document.component";
import { RepatriationComponent } from './claim-pages/claim-types-domestic-helper/repatriation/repatriation.component';
import { DetailsOfRepatriationComponent } from './claim-pages/claim-types-domestic-helper/repatriation/details-of-repatriation/details-of-repatriation.component';
import { RepatriationUploadDocumentComponent } from './claim-pages/claim-types-domestic-helper/repatriation/repatriation-upload-document/repatriation-upload-document.component';
import { RepatriationExtraCostsComponent } from './claim-pages/claim-types-domestic-helper/repatriation/repatriation-extra-costs/repatriation-extra-costs.component';
import {HospitalizationHelperComponent} from "./claim-pages/claim-types-domestic-helper/hospitalization-helper/hospitalization-helper.component";
import {HospitalizationHelperDetailComponent} from "./claim-pages/claim-types-domestic-helper/hospitalization-helper/hospitalization-helper-detail/hospitalization-helper-detail.component";
import {HospitalizationHelperUploadDocumentComponent} from "./claim-pages/claim-types-domestic-helper/hospitalization-helper/hospitalization-helper-support-doc/hospitalization-helper-upload-document.component"; 
import { DeathAndPermanentDisabilityComponent } from 'src/app/claim-pages/claim-types-domestic-helper/death-and-permanent-disability/death-and-permanent-disability.component';
import { DeathAndPermanentDisabilityDetailComponent } from 'src/app/claim-pages/claim-types-domestic-helper/death-and-permanent-disability/death-and-permanent-disability-detail/death-and-permanent-disability-detail.component';
import { DeathAndPermanentDisabilityUploadDocumentComponent } from 'src/app/claim-pages/claim-types-domestic-helper/death-and-permanent-disability/death-and-permanent-disability-support-doc/death-and-permanent-disability-upload-document.component';
import {ConfirmVehicleDetailsComponent} from './claim-pages/motor/confirm-vehicle-details/confirm-vehicle-details.component';
import {WindscreenDetailComponent} from './claim-pages/motor/claim-types-motor/windscreen/windscreen-detail/windscreen-detail.component';
import {WindscreenLocationComponent} from './claim-pages/motor/claim-types-motor/windscreen/windscreen-location/windscreen-location.component';
import {WindscreenComponent} from './claim-pages/motor/claim-types-motor/windscreen/windscreen.component';
import { WindscreenUploadDocumentComponent } from './claim-pages/motor/claim-types-motor/windscreen/windscreen-upload-document/windscreen-upload-document.component';
import {ClaimStatusSummaryComponent} from './search-claim/claim-status-summary/claim-status-summary.component';
import {ClaimSearchHomepageComponent} from './search-claim/claim-search-homepage/claim-search-homepage.component';
import { MotorAccidentComponent } from './claim-pages/motor/claim-types-motor/motor-accident-collision/motor-accident.component';
import { MAPoliceReportComponent } from './claim-pages/motor/claim-types-motor/motor-accident-collision/police-report/police-report.component';
import { MotorAccidentDetailComponent } from './claim-pages/motor/claim-types-motor/motor-accident-collision/motor-accident-detail/motor-accident-detail.component';
import { ThirdPartyDetailsComponent } from './claim-pages/motor/claim-types-motor/motor-accident-collision/third-party-details/damage-details.component';
import { MotorAccidentUploadDocumentComponent } from './claim-pages/motor/claim-types-motor/motor-accident-collision/motor-accident-support-doc/motor-accident-upload-document.component';
import { MotorDriverDetailComponent } from './claim-pages/motor/claim-types-motor/motor-accident-collision/motor-driver-detail/motor-driver-detail.component';
import { VehicleDamageDetailComponent } from './claim-pages/motor/claim-types-motor/motor-accident-collision/vehicle-damage-detail/vehicle-damage-detail.component';
import { TheftComponent } from './claim-pages/motor/claim-types-motor/theft/theft.component';
import { TheftPoliceReportComponent } from './claim-pages/motor/claim-types-motor/theft/theft-police-report/theft-police-report.component';
import { TheftIncidentAccidentDetailsComponent } from './claim-pages/motor/claim-types-motor/theft/theft-incident-accident-details/theft-incident-accident-details.component';
import { TheftUploadComponent } from './claim-pages/motor/claim-types-motor/theft/theft-upload/theft-upload.component';
import { CanDeactivateGuard } from './guard/candeactivate.guard';
import { ErrorPageComponent } from './errorpage/errorpage.component';
import { GenericSupportDocumentComponent } from './claim-pages/generic/generic-support-doc/generic-support-document.component';
import { GenericClaimDetailComponent } from './claim-pages/generic/claim-detail/generic-claim-detail.component';
import { ConfirmIncidentDetailsComponent } from './claim-pages/confirm-incident-details/confirm-incident-details.component';
import {PersonalAccidentDetailsComponent} from "./claim-pages/home/personal-accident/personal-accident-details.component";
import {GenericResponsibilityComponent} from "./claim-pages/home/generic-responsibility/generic-responsibility.component";
import { WeatherComponent } from './claim-pages/home/claim-types-home/weather/weather.component';
import { WeatherDetailsComponent } from './claim-pages/home/claim-types-home/weather/weather-details/weather-details.component';
import { WaterComponent } from './claim-pages/home/claim-types-home/water/water.component';
import { WaterDetailsComponent } from './claim-pages/home/claim-types-home/water/water-details/water-details.component';
import { ContentsBuildingDetailsComponent } from './claim-pages/home/contents-building/contents-building.component';
import { AccidentalDamageComponent } from './claim-pages/home/claim-types-home/accidental-damage/accidental-damage.component';
import { HomeItemsDetailsComponent } from './claim-pages/home/home-items/home-items-details.component';
import { DomesticEmployerComponent } from './claim-pages/home/domestic-employer/domestic-employer.component';
import { FireComponent } from './claim-pages/home/claim-types-home/fire/fire.component';
import { BurglaryComponent } from './claim-pages/home/claim-types-home/burglary/burglary.component';
import { VandalismComponent } from './claim-pages/home/claim-types-home/vandalism/vandalism.component';
import { TpLossDetailsComponent } from './claim-pages/home/third-party-liability/loss-details.component';
import { HomeOthersComponent } from './claim-pages/home/claim-types-home/others/others.component';
import { HomeUploadDocumentComponent } from './claim-pages/home/home-support-doc/home-upload-document.component';
import { OtherDetailsComponent } from './claim-pages/home/claim-types-home/others/other-details/other-details.component';
import { AuthorityReportComponent } from './claim-pages/home/authority-report/authority-report.component';
import { HomeLostPersonalItemComponent } from './claim-pages/home/claim-types-home/lost-personal-item/home-lost-personal-item.component';
import { LostDetailsComponent } from './claim-pages/home/claim-types-home/lost-personal-item/lost-details/lost-details.component';
import { LostReportComponent } from './claim-pages/home/claim-types-home/lost-personal-item/lost-report/lost-report.component';
import { HomeDamagedPersonalItemComponent } from './claim-pages/home/claim-types-home/damaged-personal-item/home-damaged-personal-item.component';
import { DamagedItemsDetailsComponent } from './claim-pages/home/claim-types-home/damaged-personal-item/damaged-items-details/damaged-items-details.component';
import { LostItemsDetailsComponent } from './claim-pages/home/claim-types-home/lost-personal-item/lost-items/lost-items-details.component';
import { DomesticEmployerClaimComponent } from './claim-pages/home/claim-types-home/domestic-employer-claim/domestic-employer-claim.component';
import { PersonalAccidentClaimComponent } from './claim-pages/home/claim-types-home/personal-accident-claim/personal-accident-claim.component';
import {ThirdPartyLiabilityClaimComponent} from './claim-pages/home/claim-types-home/third-party-liability-claim/third-party-liability-claim.component';
import { PayOverseasBankComponent } from './claim-pages/payment-contact/overseas-bank/pay-overseas-bank.component';
import { AccidentDetailsComponent } from './claim-pages/personal-accident/accident-details/accident-details.component';
import { InjuryDetailsComponent } from './claim-pages/personal-accident/injury-details/injury-details.component';
import { AccidentalMedicalExpensesComponent } from './claim-pages/personal-accident/claim-types/accidental-medical-expenses/accidental-medical-expenses.component';
import { HospitalExpensesComponent } from './claim-pages/personal-accident/claim-types/accidental-medical-expenses/hospital/hospital-expenses.component';
import { HospitalCashComponent } from './claim-pages/personal-accident/claim-types/hospital-cash/hospital-cash.component';
import { HospitalCashDetailsComponent } from './claim-pages/personal-accident/claim-types/hospital-cash/hospital-cash-details/hospital-cash-details.component';
import { ICUDetailsComponent } from './claim-pages/personal-accident/claim-types/accidental-medical-expenses/icu-details/icu-details.component';
import { AccidentalMedicalExpensesUploadDocumentComponent } from './claim-pages/personal-accident/claim-types/accidental-medical-expenses/accidental-medical-expenses-support-doc/accidental-medical-expenses-upload/accidental-medical-expenses-upload-document.component';
import { PaOthersComponent } from './claim-pages/personal-accident/claim-types/others/pa-others.component';
import { PaOtherDetailsComponent } from './claim-pages/personal-accident/claim-types/others/details/pa-other-details.component';
import { PaOthersUploadDocumentComponent } from './claim-pages/personal-accident/claim-types/others/others-support-doc/others-upload/pa-others-upload-document.component';
import { PaAuthorityReportComponent } from './claim-pages/personal-accident/claim-types/others/authority-report/authority-report.component';
import { HospitalCashUploadDocumentComponent } from './claim-pages/personal-accident/claim-types/hospital-cash/hospital-cash-support-doc/hospital-cash-upload/hospital-cash-upload-document.component';
import {OutpatientConsultComponent} from './claim-pages/personal-accident/claim-types/accidental-medical-expenses/outpatient-consult/outpatient-consult.component';
import {Form2Component} from './claim-pages/employee-compensation/claim-types/form2/form2.component';
import {Form2AComponent} from './claim-pages/employee-compensation/claim-types/form2a/form2a.component';
import {Form2BComponent} from './claim-pages/employee-compensation/claim-types/form2b/form2b.component';
import {EmployeeDetailsComponent} from './claim-pages/employee-compensation/employee-details/employee-details.component';
import {ECAccidentDetailsComponent} from './claim-pages/employee-compensation/ec-accident-details/ec-accident-details.component';
import {EmployerDetailsComponent} from './claim-pages/employee-compensation/employer-details/employer-details.component';
import {ECUploadDocumentComponent} from './claim-pages/employee-compensation/ec-upload-document/ec-upload-document.component';
import {OccupationalDiseaseComponent} from './claim-pages/employee-compensation/occupational-disease/occupational-disease.component';
import {EmployeeEarningsComponent} from './claim-pages/employee-compensation/employee-earnings/employee-earnings.component';
import {ECInsuranceDetailsComponent} from './claim-pages/employee-compensation/insurance-details/ec-insurance-details.component';
import {ECCompensationDetailsComponent} from './claim-pages/employee-compensation/ec-compensation-details/ec-compensation-details.component';
import {ECInjuryDetailsComponent} from './claim-pages/employee-compensation/injury-details/ec-injury-details.component';
import { ECAccidentPlaceComponent } from './claim-pages/employee-compensation/ec-accident-place/ec-accident-place.component';
import { ECSupplementaryInfoComponent } from './claim-pages/employee-compensation/ec-accident-place/ec-supplementary-info.component';
import {GenericSelectClaimComponent} from './claim-pages/generic/generic-select-claim/generic-select-claim.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MedicalCertificateComponent } from './claim-pages/personal-accident/claim-types/accidental-medical-expenses/medical-certificate/medical-certificate.component';
import {AcknowledgementComponent} from './claim-pages/personal-accident/acknowledgment/acknowledgement.component';
import { PaDetailsOfAccidentComponent } from './claim-pages/personal-accident/hkg/details-of-accident/details-of-accident.component';
import { PaOthersNotSureComponent } from './claim-pages/personal-accident/hkg/claim-types/pa-others-not-sure.component';
import { PaHospitalCashComponent } from './claim-pages/personal-accident/hkg/claim-types/pa-hospital-cash.component';
import { PaAmeComponent } from './claim-pages/personal-accident/hkg/claim-types/pa-ame.component';

const appRoutes: Routes = [
    {path: 'country/:country/product/:product', component: ClaimBootComponent, pathMatch: 'full' },
    {path: 'country/:country/action/:action/product/:product', component: ClaimBootComponent, pathMatch: 'full'},
    {path: 'country/:country/action/:action/product/:product/policyNumber/:policyNumber', component: ClaimBootComponent, pathMatch: 'full'},
    {path: '', component: ClaimBootComponent, pathMatch: 'full'},
    {path: 'boot', component: ClaimBootComponent},
    {path: 'homepage', component: HomepageComponent},
    {path: 'search-claim-homepage', component: ClaimSearchHomepageComponent},
    {path: 'claim-status-summary', component: ClaimStatusSummaryComponent},
    {path: 'register', component: ResetPasswordComponent},
    {path: 'resetPassword', component: ResetPasswordComponent},
    {
        path: 'claimform',
        component: ClaimFormComponent,
        children: [
            {
                path: 'getStart',
                component: GetStartedComponent,
                canActivate: [PermissionGuard],

            },
            {
                path: 'confirmTravelPeriod',
                component: ConfirmTravelPeriodComponent,
                canActivate: [PermissionGuard],
            },
            {
                path: 'confirmVehicleDetails',
                component: ConfirmVehicleDetailsComponent,
                canActivate: [PermissionGuard],
            },
            {
                path: 'confirmIncidentDetails',
                component: ConfirmIncidentDetailsComponent,
                canActivate: [PermissionGuard],
            },
            {
                path: 'employerDetails',
                component: EmployerDetailsComponent,
                canActivate: [PermissionGuard],
            },
            {
                path: 'selectInsured',
                component: SelectInsuredComponent,
                canActivate: [PermissionGuard],

            },
            {
                path: 'claimDetail',
                component: ClaimDetailComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: '',
                        redirectTo: 'selectClaim',
                        pathMatch: 'full'
                    },
                    {
                        path: 'selectClaim',
                        component: SelectClaimComponent,
                    },
                ]
            },
            {
                path: 'genericSelectClaim',
                component: GenericSelectClaimComponent,
                canActivate: [PermissionGuard],
            },
            {
                path: 'genericClaimDetail',
                component: GenericClaimDetailComponent,
                canActivate: [PermissionGuard],
            },
            {
                path: 'genericUploadDocument',
                component: GenericSupportDocumentComponent,
                canActivate: [PermissionGuard],
            },
            {
                path: 'travelDelayed',
                component: TravelDelayComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailOfTravelDelay',
                        component: DetailOfTravelDelayComponent,
                    },
                    {
                        path: 'reasonOfTravelDelay',
                        component: ReasonOfTravelDelayComponent,
                    },
                    {
                        path: 'travelExtralCost',
                        component: TravelExtralCostComponent,
                    },
                    {
                        path: 'travelSupportDoc',
                        component: TravelSupportDocComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'uploadDocument',
                                pathMatch: 'full'
                            },
                            {
                                path: 'uploadDocument',
                                component: UploadDocumentComponent,
                            }
                        ]
                    },
                ]
            },
            {
                path: 'lostPersonalItem',
                component: LostPersonalItemComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailOfLoss',
                        component: DetailOfLossComponent
                    },
                    {
                        path: 'detailsOfLossItems',
                        component: DetailsOfLostItemsComponent
                    },
                    {
                        path: 'reportTheLoss',
                        component: ReportTheLossComponent
                    },
                    {
                        path: 'lossSupportDoc',
                        component: LossSupportDocComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'uploadDocument',
                                pathMatch: 'full'
                            },
                            {
                                path: 'uploadDocument',
                                component: LossUploadDocumentComponent
                            },
                        ]
                    }
                ]
            },
            {
                path: 'damagePersonalItem',
                component: DamagePersonalItemComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailOfDamage',
                        component: DetailOfDamageComponent
                    },
                    {
                        path: 'detailsOfDamageItems',
                        component: DetailOfDamageItemsComponent
                    },
                    {
                        path: 'reportTheDamage',
                        component: ReportTheDamageComponent
                    },
                    {
                        path: 'damageSupportDoc',
                        component: DamageSupportDocComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'uploadDocument',
                                pathMatch: 'full'
                            },
                            {
                                path: 'uploadDocument',
                                component: DamageUploadDocumentComponent
                            },
                        ]
                    }
                ]
            },
            {
                path: 'baggageDelay',
                component: BaggageDelayComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailsOfBaggageDelay',
                        component: DetailsOfBaggageDelayComponent
                    },
                    {
                        path: 'emergencyPurchasedItem',
                        component: EmergencyPurchasedItemComponent
                    },
                    {
                        path: 'baggageDelaySupportDoc',
                        component: BaggageDelaySupportDocComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'uploadDocument',
                                pathMatch: 'full'
                            },
                            {
                                path: 'uploadDocument',
                                component: BaggageDelayUploadDocumentComponent
                            },
                        ]
                    }
                ]
            },
            {
                path: 'damagePersonalItem',
                component: DamagePersonalItemComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailOfDamage',
                        component: DetailOfDamageComponent
                    },
                    {
                        path: 'detailsOfDamageItems',
                        component: DetailOfDamageItemsComponent
                    },
                    {
                        path: 'reportTheDamage',
                        component: ReportTheDamageComponent
                    },
                    {
                        path: 'damageSupportDoc',
                        component: DamageSupportDocComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'uploadDocument',
                                pathMatch: 'full'
                            },
                            {
                                path: 'uploadDocument',
                                component: DamageUploadDocumentComponent
                            },
                        ]
                    }
                ]
            },
            {
                path: 'visitedADoctor',
                component: VisitedDoctorComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailsOfIllness',
                        component: DetailOfIllnessComponent
                    },
                    {
                        path: 'detailsOfOverseaDoctorVisit',
                        component: DetailsOfOverseaDoctorVisitComponent
                    },
                    {
                        path: 'followUpTreatment',
                        component: FollowUpTreatmentComponent
                    },
                    {
                        path: 'medicalBills',
                        component: MedicalBillsComponent
                    },
                    {
                        path: 'doctorSupportDoc',
                        component: VisitedDoctorSupportDocComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'uploadDocument',
                                pathMatch: 'full'
                            },
                            {
                                path: 'uploadDocument',
                                component: VisitedDoctorUploadDocumentComponent
                            },
                        ]
                    }
                ]
            },
            {
                path: 'tripCancellation',
                component: TripCancellationComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailOfCancelTrip',
                        component: DetailOfCancelTripComponent
                    },
                    {
                        path: 'lossCauseByCancellation',
                        component: LossCausedByCancellationComponent
                    },
                    {
                        path: 'cancelSupportDoc',
                        component: CancelSupportDocComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'uploadDocument',
                                pathMatch: 'full'
                            },
                            {
                                path: 'uploadDocument',
                                component: CancelUploadDocumentComponent
                            },
                        ]
                    }
                ]
            },
            {
                path: 'tripCurtailment',
                component: TripCurtailmentComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailOfCurtailmentTrip',
                        component: DetailOfTripCurtailmentComponent
                    },
                    {
                        path: 'lossCauseByCurtailment',
                        component: LossCausedByCurtailmentComponent
                    },
                    {
                        path: 'extraCost',
                        component: ExtraCostComponent
                    },
                    {
                        path: 'curtailmentSupportDoc',
                        component: CurtailmentSupportDocComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'uploadDocument',
                                pathMatch: 'full'
                            },
                            {
                                path: 'uploadDocument',
                                component: CurtailmentUploadDocumentComponent
                            },
                        ]
                    }
                ]
            },
            {
                path: 'stayInHospital',
                component: StayInHospitalComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailsOfIllness',
                        component: DetailOfHospitalIllnessComponent
                    },
                    {
                        path: 'detailsOfOverseaDoctorVisit',
                        component: DetailOfOverseaHospitalStayComponent
                    },
                    {
                        path: 'followUpTreatment',
                        component: HospitalFollowUpTreatmentComponent
                    },
                    {
                        path: 'medicalBills',
                        component: HospitalMedicalBillsComponent
                    },
                    {
                        path: 'hospitalSupportDoc',
                        component: HospitalSupportDocComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'uploadDocument',
                                pathMatch: 'full'
                            },
                            {
                                path: 'uploadDocument',
                                component: HospitalUploadDocumentComponent
                            },
                        ]
                    }
                ]
            },
            {
                path: 'rentalVehicleExcess',
                component: RentalVehicleComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailsOfIncident',
                        component: DetailOfIncidentComponent
                    },                   
                    {
                        path: 'vehicleSupportDoc',
                        component: VehicleSupportDocComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'uploadDocument',
                                pathMatch: 'full'
                            },
                            {
                                path: 'uploadDocument',
                                component: VehicleUploadDocumentComponent
                            },
                        ]
                    }
                ]
            }
            ,
            {
                path: 'reRouting',
                component: TravelReroutingComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailsOfRerouting',
                        component: ReroutingDetailComponent
                    },
                    {
                        path: 'meansOfTransportation',
                        component: MeansOfTransportationComponent
                    },
                    {
                        path: 'reroutingSupportDoc',
                        component: TravelReroutingComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'uploadDocument',
                                pathMatch: 'full'
                            },
                            {
                                path: 'uploadDocument',
                                component: ReroutingUploadDocumentComponent
                            },
                        ]
                    }
                ]
            },
            {
                path: 'missedEvent',
                component: MissEventComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailOfMissEvent',
                        component: DetailOfMissEventComponent
                    },
                    {
                        path: 'lossOfMissEvent',
                        component: LossOfMissEventComponent
                    },
                    {
                        path: 'missEventSupportDoc',
                        component: MissEventSuppoortDocComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'uploadDocument',
                                pathMatch: 'full'
                            },
                            {
                                path: 'uploadDocument',
                                component: MissEventUploadDocumentComponent
                            },
                        ]
                    }
                ]
            },
            {
                path: 'missedConnect',
                component: MissConnectComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailOfMissConnect',
                        component: DetailOfMissConnectComponent
                    },
                    {
                        path: 'lossOfMissConnect',
                        component: LossOfMissConnectComponent
                    },
                    {
                        path: 'missConnectSupportDoc',
                        component: MissConnectSupportDocComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'uploadDocument',
                                pathMatch: 'full'
                            },
                            {
                                path: 'uploadDocument',
                                component: MissConnectUploaDocumentComponent
                            },
                        ]
                    }
                ]
            },
            {
                path: 'personalAccident',
                component: PersonalAccidentComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailOfAccident',
                        component: DetailOfAccidentComponent
                    },
                    {
                        path: 'detailOfOversea',
                        component: DetailOfOverseaHospitalComponent
                    },
                    {
                        path: 'followUpTreatment',
                        component: AccidentFollowUpTreamentComponent
                    },
                    {
                        path: 'medicalBills',
                        component: AccidentMedicalBillsComponent
                    },
                    {
                        path: 'accidentSupportDoc',
                        component: AccidentSupportDocComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'uploadDocument',
                                pathMatch: 'full'
                            },
                            {
                                path: 'uploadDocument',
                                component: AccidentUploadDocumentComponent
                            },
                        ]
                    }
                ]
            },
            {
                path: 'personalLiability',
                component: PersonalLiabilityComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailOfIncident',
                        component: LiabilityDetailOfIncidentComponent
                    },
                    {
                        path: 'descOfIncident',
                        component: LiabilityDescOfIncidentComponent
                    },
                    {
                        path: 'liabilitySupportDoc',
                        component: LiablitySupportDocComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'uploadDocument',
                                pathMatch: 'full'
                            },
                            {
                                path: 'uploadDocument',
                                component: LiablityUploadDocumentComponent
                            },
                        ]
                    }
                ]
            },
            {
                path: 'otherClaim',
                component: OtherClaimComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailsOfIncident',
                        component: DetailsOfIncidentComponent
                    },
                    {
                        path: 'descOfIncident',
                        component: DescOfIncidentComponent
                    },
                    {
                        path: 'supportDoc',
                        component: OtherClaimSupportDocComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'uploadDocument',
                                pathMatch: 'full'
                            },
                            {
                                path: 'uploadDocument',
                                component: OtherClaimUploadDocumentComponent
                            },
                        ]
                    }
                ]
            },

            // domestic helper - claim details START
            {
                path: 'clinical',
                component: ClinicalExpensesComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailsOfClinicalExpenses',
                        component: ClinicalExpensesDetailComponent
                    },
                    {
                        path: 'relatedConditions',
                        component: ClinicalExpensesConditionsComponent
                    },
                    {
                        path: 'detailsOfClinicalVisit',
                        component: ClinicalExpensesVisitComponent
                    },
                    {
                        path: 'supportDoc',
                        component: ClinicalExpenseUploadDocumentComponent
                    }
                ]
            },
            {
                path: 'dental',
                component: DentalExpensesComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailsOfInjuryIllnessComponent',
                        component: DetailsOfInjuryIllnessComponent
                    },
                    {
                        path: 'dentalExpenseIncurred',
                        component: DentalExpenseIncurred
                    },
                    {
                        path: 'supportDoc',
                        component: DentalExpenseUploadDocumentComponent
                    }
                ]
            },
            {
                path: 'helpersLiability',
                component: HelpersLiabilityComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailOfIncident',
                        component: HelpersLiabilityDetailComponent
                    },
                    {
                        path: 'descriptionOfIncident',
                        component: HelpersLiabilityIncidentComponent
                    },
                    {
                        path: 'reportingOfPolicy',
                        component: HelpersLiabilityReportingPolicyComponent
                    },
                    {
                        path: 'supportDoc',
                        component: HelpersLiabilityUploadDocumentComponent
                    }
                ]
            },
            {
                path: 'employeeCompensation',
                component: EmployeeCompensationComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailsOfEmployeeCompensation',
                        component: EmployeeCompensationDetailComponent
                    },
                    {
                        path: 'supportDoc',
                        component: EmployeeCompensationUploadDocumentComponent
                    }
                ]
            },
        {
            path: 'dishonestyHelper',
            component: DishonestyHelperComponent,
            canActivate: [PermissionGuard],
            children: [
                {
                    path: 'detailsOfIncident',
                    component: DishonestyHelperDetailComponent
                },
                {
                    path: 'supportDoc',
                    component: DishonestyHelperUploadDocumentComponent
                }
            ]
        },
        {
            path: 'deathAndPermanentDisability',
            component: DeathAndPermanentDisabilityComponent,
            canActivate: [PermissionGuard],
            children: [
                {
                    path: 'detailsOfDeathAndPermanentDisability',
                    component: DeathAndPermanentDisabilityDetailComponent
                },
                {
                    path: 'supportDoc',
                    component: DeathAndPermanentDisabilityUploadDocumentComponent
                }
            ]
        },
        {
        path: 'hospitalization',
        component: HospitalizationHelperComponent,
        canActivate: [PermissionGuard],
        children: [
                {
                    path: 'detailsOfIncident',
                    component: HospitalizationHelperDetailComponent
                },
                {
                    path: 'supportDoc',
                    component: HospitalizationHelperUploadDocumentComponent
                }
            ]
        },  
        {
        path: 'other',
        component: OtherClaimDMHComponent,
        canActivate: [PermissionGuard],
        children: [
                {
                    path: 'detailsOfIncident',
                    component: OtherClaimDMHDetailComponent
                },
                {
                    path: 'supportDoc',
                    component: OtherClaimDMHUploadDocumentComponent
                }
            ]
        },              // domestic helper claim details END

           {
                path: 'repatriation',
                component: RepatriationComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailsOfIncident',
                        component: DetailsOfRepatriationComponent
                    },
                    {
                        path: 'extraCosts',
                        component: RepatriationExtraCostsComponent
                    },
                    {
                        path: 'supportDoc',
                        component: RepatriationUploadDocumentComponent
                    }
                ]
            },

            // domestic helper claim details END
            // motor claim details START
            {
                path: 'windscreen',
                component: WindscreenComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'placeOfIncident',
                        component: WindscreenLocationComponent
                    },
                    {
                        path: 'detailOfDamage',
                        component: WindscreenDetailComponent
                    },
                    {
                        path: 'supportDoc',
                        component: WindscreenUploadDocumentComponent
                    }
                ]
            },
            {
                path: 'theft',
                component: TheftComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'policeReport',
                        component: TheftPoliceReportComponent
                    },
                    {
                        path: 'incidentAccidentDetails',
                        component: TheftIncidentAccidentDetailsComponent
                    },
                    {
                        path: 'supportDoc',
                        component: TheftUploadComponent
                    }
                ]
            },
            {
                path: 'collission',
                component: MotorAccidentComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'accidentDetails',
                        component: MotorAccidentDetailComponent,
                    },
                    {
                        path: 'driverDetails',
                        component: MotorDriverDetailComponent,
                    },
                    {
                        path: 'vehicleDamage',
                        component: VehicleDamageDetailComponent,
                    },
                    {
                        path: 'thirdPartyDetails',
                        component: ThirdPartyDetailsComponent,
                    },
                    {
                        path: 'policeReport',
                        component: MAPoliceReportComponent,
                    },
                    {
                        path: 'supportDoc',
                        component: MotorAccidentUploadDocumentComponent,
                    }
                ]
            },
            // motor claim details END
            // Home details start
            {
                path: 'water',
                component: WaterComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'details',
                        component: WaterDetailsComponent
                    },
                    {
                        path: 'homeItems',
                        component: HomeItemsDetailsComponent
                    },
                    {
                        path: 'thirdPartyLossDetails',
                        component: TpLossDetailsComponent
                    },
                    {
                        path: 'domesticEmployer',
                        component: DomesticEmployerComponent,
                    },
                    {
                        path: 'personalAccidentDetails',
                        component: PersonalAccidentDetailsComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'responsibility',
                        component: GenericResponsibilityComponent,
                        canActivate: [PermissionGuard],
                    },{
                        path: 'supportDoc',
                        component: HomeUploadDocumentComponent,
                        canActivate: [PermissionGuard],
                    }
                ]
            },
            {
                path: 'weather',
                component: WeatherComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'details',
                        component: WeatherDetailsComponent
                    },
                    {
                        path: 'homeItems',
                        component: HomeItemsDetailsComponent
                    },
                    {
                        path: 'thirdPartyLossDetails',
                        component: TpLossDetailsComponent
                    },
                    {
                        path: 'domesticEmployer',
                        component: DomesticEmployerComponent,
                    },
                    {
                        path: 'personalAccidentDetails',
                        component: PersonalAccidentDetailsComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'responsibility',
                        component: GenericResponsibilityComponent,
                        canActivate: [PermissionGuard],
                    },{
                        path: 'supportDoc',
                        component: HomeUploadDocumentComponent,
                        canActivate: [PermissionGuard],
                    }
                ]
            },
            {
                path: 'accidentalDamage',
                component: AccidentalDamageComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'homeItems',
                        component: HomeItemsDetailsComponent
                    },
                    {
                        path: 'thirdPartyLossDetails',
                        component: TpLossDetailsComponent
                    },
                    {
                        path: 'domesticEmployer',
                        component: DomesticEmployerComponent,
                    },
                    {
                        path: 'personalAccidentDetails',
                        component: PersonalAccidentDetailsComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'responsibility',
                        component: GenericResponsibilityComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'supportDoc',
                        component: HomeUploadDocumentComponent,
                        canActivate: [PermissionGuard],
                    }
                ]
            }, 
            {
                path: 'fire',
                component: FireComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'contentsBuilding',
                        component: ContentsBuildingDetailsComponent
                    },
                    {
                        path: 'homeItems',
                        component: HomeItemsDetailsComponent
                    },
                    {
                        path: 'authorityReport',
                        component: AuthorityReportComponent
                    },
                    {
                        path: 'thirdPartyLossDetails',
                        component: TpLossDetailsComponent
                    },
                    {
                        path: 'domesticEmployer',
                        component: DomesticEmployerComponent,
                    },
                    {
                        path: 'personalAccidentDetails',
                        component: PersonalAccidentDetailsComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'responsibility',
                        component: GenericResponsibilityComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'supportDoc',
                        component: HomeUploadDocumentComponent,
                        canActivate: [PermissionGuard],
                    }
                ]
            }, 
            {
                path: 'burglary',
                component: BurglaryComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'contentsBuilding',
                        component: ContentsBuildingDetailsComponent
                    },
                    {
                        path: 'homeItems',
                        component: HomeItemsDetailsComponent
                    },
                    {
                        path: 'authorityReport',
                        component: AuthorityReportComponent
                    },
                    {
                        path: 'thirdPartyLossDetails',
                        component: TpLossDetailsComponent
                    },
                    {
                        path: 'domesticEmployer',
                        component: DomesticEmployerComponent,
                    },
                    {
                        path: 'personalAccidentDetails',
                        component: PersonalAccidentDetailsComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'responsibility',
                        component: GenericResponsibilityComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'supportDoc',
                        component: HomeUploadDocumentComponent,
                        canActivate: [PermissionGuard],
                    }
                ]
            }, 
            {
                path: 'vandalism',
                component: VandalismComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'contentsBuilding',
                        component: ContentsBuildingDetailsComponent
                    },
                    {
                        path: 'homeItems',
                        component: HomeItemsDetailsComponent
                    },
                    {
                        path: 'authorityReport',
                        component: AuthorityReportComponent
                    },
                    {
                        path: 'thirdPartyLossDetails',
                        component: TpLossDetailsComponent
                    },
                    {
                        path: 'domesticEmployer',
                        component: DomesticEmployerComponent,
                    },
                    {
                        path: 'personalAccidentDetails',
                        component: PersonalAccidentDetailsComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'responsibility',
                        component: GenericResponsibilityComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'supportDoc',
                        component: HomeUploadDocumentComponent,
                        canActivate: [PermissionGuard],
                    }
                ]
            }, 
            {
                path: 'domesticEmployerClaim',
                component: DomesticEmployerClaimComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'domesticEmployer',
                        component: DomesticEmployerComponent,
                    },
                    {
                        path: 'responsibility',
                        component: GenericResponsibilityComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'supportDoc',
                        component: HomeUploadDocumentComponent,
                        canActivate: [PermissionGuard],
                    }
                ]
            },  
            {
                path: 'personalAccidentClaim',
                component: PersonalAccidentClaimComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'personalAccidentDetails',
                        component: PersonalAccidentDetailsComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'responsibility',
                        component: GenericResponsibilityComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'supportDoc',
                        component: HomeUploadDocumentComponent,
                        canActivate: [PermissionGuard],
                    }
                ]
            },
            {
                path: 'thirdPartyLiabilityClaim',
                component: ThirdPartyLiabilityClaimComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'lossDetails',
                        component: TpLossDetailsComponent
                    },
                    {
                        path: 'responsibility',
                        component: GenericResponsibilityComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'supportDoc',
                        component: HomeUploadDocumentComponent,
                        canActivate: [PermissionGuard]
                    }
                ]
            },
            {
                path: 'othersHome',
                component: HomeOthersComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'details',
                        component: OtherDetailsComponent,
                        canActivate: [PermissionGuard]
                    },
                    {
                        path: 'thirdPartyLossDetails',
                        component: TpLossDetailsComponent
                    },
                    {
                        path: 'domesticEmployer',
                        component: DomesticEmployerComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'personalAccidentDetails',
                        component: PersonalAccidentDetailsComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'responsibility',
                        component: GenericResponsibilityComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'supportDoc',
                        component: HomeUploadDocumentComponent,
                        canActivate: [PermissionGuard],
                    }
                ]
            },
            {
                path: 'homeLostPersonalItem',
                component: HomeLostPersonalItemComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailOfLoss',
                        component: LostDetailsComponent
                    },
                    {
                        path: 'detailsOfLossItems',
                        component: LostItemsDetailsComponent
                    },
                    {
                        path: 'reportTheLoss',
                        component: LostReportComponent
                    },
                    {
                        path: 'domesticEmployer',
                        component: DomesticEmployerComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'personalAccidentDetails',
                        component: PersonalAccidentDetailsComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'responsibility',
                        component: GenericResponsibilityComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'supportDoc',
                        component: HomeUploadDocumentComponent,
                        canActivate: [PermissionGuard],
                    }
                ]
            },
            {
                path: 'homeDamagedPersonalItem',
                component: HomeDamagedPersonalItemComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailsOfDamageItems',
                        component: DamagedItemsDetailsComponent
                    },
                    {
                        path: 'domesticEmployer',
                        component: DomesticEmployerComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'personalAccidentDetails',
                        component: PersonalAccidentDetailsComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'responsibility',
                        component: GenericResponsibilityComponent,
                        canActivate: [PermissionGuard],
                    },
                    {
                        path: 'supportDoc',
                        component: HomeUploadDocumentComponent,
                        canActivate: [PermissionGuard],
                    }
                ]
            },
            {   
                path: 'thirdPartyLossDetails',
                component: TpLossDetailsComponent
            },
            {
                path: 'domesticEmployer',
                component: DomesticEmployerComponent,
                canActivate: [PermissionGuard],
            },
            {
                path: 'personalAccidentDetails',
                component: PersonalAccidentDetailsComponent,
                canActivate: [PermissionGuard],
            },
            {
                path: 'responsibility',
                component: GenericResponsibilityComponent,
                canActivate: [PermissionGuard],
            },
            // Home claim details END
            // Personal Accident start
            {
                path: 'acknowledgement',
                component: AcknowledgementComponent,
                canActivate: [PermissionGuard]
            },
            {
                path: 'paAccidentDetails',
                component: AccidentDetailsComponent,
                canActivate: [PermissionGuard]
            },
            {
                path: 'paDetailsOfAccident',
                component: PaDetailsOfAccidentComponent,
                canActivate: [PermissionGuard]
            },
            {
                path: 'paInjuryDetails',
                component: InjuryDetailsComponent,
                canActivate: [PermissionGuard]
            },
            {
                path: 'paAccidentalMedicalExpenses',
                component: PaAmeComponent,
                canActivate: [PermissionGuard]
            },
            {
                path: 'accidentalMedicalExpenses',
                component: AccidentalMedicalExpensesComponent,
                canActivate: [PermissionGuard],
                children: [
                    {   
                        path: 'hospital',
                        component: HospitalExpensesComponent
                    },
                    {
                        path: 'icuDetails',
                        component: ICUDetailsComponent
                    },
                    {
                        path: 'outpatient',
                        component: OutpatientConsultComponent
                    },
                    {
                        path: 'medicalCertificates',
                        component: MedicalCertificateComponent
                    },
                    {
                        path: 'supportDoc',
                        component: AccidentalMedicalExpensesUploadDocumentComponent
                    }
                ]
            },
            {
                path: 'paHospitalCash',
                component: PaHospitalCashComponent,
                canActivate: [PermissionGuard]
            },
            {
                path: 'hospitalCash',
                component: HospitalCashComponent,
                canActivate: [PermissionGuard],
                children: [
                    {   
                        path: 'hospitalCashDetails',
                        component: HospitalCashDetailsComponent
                    },
                    {
                        path: 'supportDoc',
                        component: HospitalCashUploadDocumentComponent
                    }
                ]
            },
            {
                path: 'paOthersNotSure',
                component: PaOthersNotSureComponent,
                canActivate: [PermissionGuard]
            },
            {
                path: 'paOthers',
                component: PaOthersComponent,
                canActivate: [PermissionGuard],
                children: [
                    {   
                        path: 'details',
                        component: PaOtherDetailsComponent
                    },
                    {   
                        path: 'authorityReport',
                        component: PaAuthorityReportComponent
                    },
                    {
                        path: 'supportDoc',
                        component: PaOthersUploadDocumentComponent
                    }
                ]
            },
            // Personal Accident end
            // Employee Compensation Details start
            {
                path: 'form2',
                component: Form2Component,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailsOfEmployee',
                        component: EmployeeDetailsComponent
                    },
                    {
                        path: 'placeOfAccident',
                        component: ECAccidentPlaceComponent
                    },
                    {
                        path: 'supplementaryInfo',
                        component: ECSupplementaryInfoComponent
                    },
                    {
                        path: 'detailsOfAccident',
                        component: ECAccidentDetailsComponent
                    },
                    {
                        path: 'detailsOfInjury',
                        component: ECInjuryDetailsComponent
                    },
                    {
                        path: 'employeeEarnings',
                        component: EmployeeEarningsComponent
                    },
                    {
                        path: 'directSettlement',
                        component: ECCompensationDetailsComponent
                    },
                    {
                        path: 'insuranceDetails',
                        component: ECInsuranceDetailsComponent
                    },
                    {
                        path: 'supportingDoc',
                        component: ECUploadDocumentComponent
                    }
                ]
            },
            {
                path: 'form2a',
                component: Form2AComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailsOfEmployee',
                        component: EmployeeDetailsComponent
                    },
                    {
                        path: 'occupationalDisease',
                        component: OccupationalDiseaseComponent
                    },
                    {
                        path: 'employeeEarnings',
                        component: EmployeeEarningsComponent
                    },
                    {
                        path: 'directSettlement',
                        component: ECCompensationDetailsComponent
                    },
                    {
                        path: 'insuranceDetails',
                        component: ECInsuranceDetailsComponent
                    },
                    {
                        path: 'supportingDoc',
                        component: ECUploadDocumentComponent
                    }
                ]
            },
            {
                path: 'form2b',
                component: Form2BComponent,
                canActivate: [PermissionGuard],
                children: [
                    {
                        path: 'detailsOfEmployee',
                        component: EmployeeDetailsComponent
                    },
                    {
                        path: 'detailsOfAccident',
                        component: ECAccidentDetailsComponent
                    },
                    {
                        path: 'compensationDetails',
                        component: ECCompensationDetailsComponent
                    },
                    {
                        path: 'supportingDoc',
                        component: ECUploadDocumentComponent
                    }
                ]
            },
            // Employee Compensation Details end

            {
                path: 'moreClaimType',
                component: MoreClaimTypeComponent,
                canActivate: [PermissionGuard]
            },
            {
                path: 'anotherInsurance',
                component: AnotherInsuranceComponent,
                canActivate: [PermissionGuard]
            },
            {
                path: 'paymentAndContact',
                component: PaymentContactComponent,
                canActivate: [PermissionGuard],
                children:
                    [
                        {
                            path: "",
                            redirectTo: "bankAccount",
                            pathMatch: 'full'
                        },
                        {
                            path: 'bankAccount',
                            component: BankAccountComponent
                        },
                        {
                            path: 'sendCheque',
                            component: SendChequeComponent
                        },
                        {
                            path:'payOverseasBank',
                            component: PayOverseasBankComponent
                        },
                        {
                            path: 'provideContactInfo',
                            component: ProvideContactInfoComponent
                        }
                    ]
            },
            {
                path: 'summary',
                component: SummaryOfClaimComponent,
                canActivate: [PermissionGuard],
            },


        ]
    },
    {
        path: 'complete',
        component: CompleteComponent,
        canDeactivate: [CanDeactivateGuard]
    },
    {
        path: 'error',
        component: ErrorPageComponent
    },
    {
        path: '**', 
        component: ErrorPageComponent, 
        data: { error: 404 }
    }


];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(
            appRoutes
        )
    ],
    exports: [RouterModule],
    declarations: []
})
export class AppRoutingModule {
}
