<div>
  <p>Welcome</p>
  <div class="submit-bar">
    <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<em class="fas fa-arrow-right"></em></button>
  </div>
  <div class="policy-details-block">
    <div class="policy-details-row">
      <span class="detail-title">{{ 'paClaim.acknowledgement.policyNumber' | translate}}</span>
      <span class="detail-desc">{{claim.getPolicyNumber()}}</span>
    </div>
    <div class="policy-details-row">
      <span class="detail-title">{{ 'paClaim.acknowledgement.beneficiary' | translate}}</span>
      <span class="detail-desc">{{beneficiary}}</span>
    </div>
    <div class="policy-details-row">
      <span class="detail-title">{{ 'paClaim.acknowledgement.policyOwner' | translate}}</span>
      <span class="detail-desc">{{claim.getPolicyHolderName()}}</span>
    </div>
    <div class="policy-details-row">
      <span class="detail-title">{{ 'paClaim.acknowledgement.coveragePeriod' | translate}}</span>
      <div class="detail-desc">
        <span>{{getDate(transactionInfo.getPolicyStartDate())}}&nbsp;-&nbsp;{{getDate(transactionInfo.getPolicyEndDate())}}</span>
      </div>
    </div>
  </div>
  <div class="acknowledgement-block-splitter">
    <div class="splitter-excess"></div>
  </div>
  <div class="acknowledgement-cta policy-details-block">
    <div>
      <p>{{ 'paClaim.acknowledgement.content' | translate}}</p>
    </div>
    <div class="submit-bar">
      <button (click)="goToNext()" class="btn btn-primary new-btn">{{ 'paClaim.acknowledgement.acknowledgeContinue' | translate}}</button>
      <a href="cancel()" class="btn btn-cancel btn-secondary">{{ 'claimSection.generalLabels.generalButtonLabels.cancel' | translate }}</a>
    </div>
  </div>
</div>

