import {Component, Injector, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimService } from 'src/app/services/claim.service';
import { ClaimPersonalAccident } from 'src/app/model/claim-personal-accident.model';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { SideMenuService } from 'src/app/services/side-menu.service';
import {HospitalExpenseHelperService} from '../../../../../services/hospital-expense-helper.service';
import {ClinicalExpenseDetails} from '../../../../../model/personal-accident/clinical-expense-details.model';
import {takeUntil} from 'rxjs/operators';
import {OutpatientConsultDetails} from '../../../../../model/personal-accident/outpatient-consult-details.model';

@Component({
    selector: 'app-outpatient-consult',
    templateUrl: './outpatient-consult.component.html'
})
export class OutpatientConsultComponent extends ClaimsBaseComponent  implements OnInit {

    showTotalBox = false;
    outpatientForm: FormGroup;
    transactionInfo: TransactionInfo;
    claimPA: ClaimPersonalAccident;
    currency: string;
    hospitalExpenseHelperService: HospitalExpenseHelperService;
    clinicalExpenseForm: FormGroup;
    clinicalExpenseItems: ClinicalExpenseDetails[];
    outpatientConsultation: OutpatientConsultDetails;

    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private sideMenuService: SideMenuService) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimPA = this.claim.getClaimPersonalAccident();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
        this.hospitalExpenseHelperService = this.injector.get(HospitalExpenseHelperService);
        this.currency = this.claim.getCountry().toLowerCase() === 'sgp' ? 'SGD' : 'HKD';
        this.outpatientConsultation = this.claimPA.getAccidentalMedicalExpenses().getOutpatientConsultation();
    }

    ngOnInit() {
        this.pushGAPageView();
        this.outpatientForm = this.fb.group({
            hasOutpatientConsultations: [super.getBooleanString(this.outpatientConsultation.getHasOutpatientConsultations())]
        });

        this.clinicalExpenseItems = this.outpatientConsultation.getClinicalExpenses();
        this.clinicalExpenseForm = this.fb.group({
            fullyRecovered: [super.getBooleanString(this.outpatientConsultation.getFullyRecovered())],
            treatment: [this.outpatientConsultation.getTreatment()],
            items: this.fb.array(this.buildClinicalExpenseItems(this.clinicalExpenseItems))
        })

    }

    ngAfterViewInit() {


        this.outpatientForm.valueChanges.subscribe(data => {
            if (this.outpatientForm.get('hasOutpatientConsultations').value !== null) {
                this.outpatientConsultation.setHasOutpatientConsultations(this.outpatientForm.get('hasOutpatientConsultations').value == 'true');
            }
        });

        this.clinicalExpenseForm.valueChanges.subscribe(data => {
            let clinicalExpenses: ClinicalExpenseDetails[] = [];

            for (let i = 0; i < data.items.length; i++) {

                let itemDetail : ClinicalExpenseDetails = new ClinicalExpenseDetails();
                itemDetail.setDateVisit(super.getIndexedValueInComponent("dateVisit", i));
                itemDetail.setMedicalAttention(data.items[i].medicalAttention);
                itemDetail.setMedicalAttentionDesc(super.getIndexedTextInComponent("medicalAttention", i));
                itemDetail.setMedicalAttentionOthers(data.items[i].medicalAttentionOthers);
                itemDetail.setClinicalExpense(data.items[i].clinicalExpense);
                itemDetail.setClinicalExpenseCurrency(data.items[i].clinicalExpenseCurrency);
                clinicalExpenses.push(itemDetail);

            }

            this.outpatientConsultation.setClinicalExpenses(clinicalExpenses);
            if (this.clinicalExpenseForm.get('fullyRecovered').value !== null) {
                this.outpatientConsultation.setFullyRecovered(this.clinicalExpenseForm.get('fullyRecovered').value == 'true');
            }
            this.outpatientConsultation.setTreatment(super.getValueInComponent('treatment'));
        });

        let self = this;

        setTimeout(() => {self.hideFields(self.getBooleanString(self.outpatientConsultation.getHasOutpatientConsultations()))},10);
        this.outpatientForm.get('hasOutpatientConsultations').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideFields(data);
        });


        setTimeout(() => {self.hideTreatmentField(self.getBooleanString(self.outpatientConsultation.getFullyRecovered()))},10);
        this.clinicalExpenseForm.get('fullyRecovered').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideTreatmentField(data);
        });

        let formArray : FormArray =  <FormArray> self.clinicalExpenseForm.controls["items"];
        formArray.controls.forEach(function (formArrayItem) {
            formArrayItem.get('medicalAttention').valueChanges.pipe(takeUntil(self.ngUnsubscribe)).subscribe(data => {
                self.medicalAttentionChanged(data, formArrayItem);
            });
        });

    }


    medicalAttentionChanged(medicalAttention, formGroup) {
        if (medicalAttention === 'Others') {
            formGroup.get('medicalAttentionOthers').enable({onlySelf: false, emitEvent: false});
        } else  {
            formGroup.get('medicalAttentionOthers').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideTreatmentField(hasRecovered) {
        if (String(hasRecovered) === 'false') {
            this.clinicalExpenseForm.get('treatment').enable({onlySelf: false, emitEvent: false});
        } else if (String(hasRecovered) === 'true') {
            this.clinicalExpenseForm.get('treatment').reset();
            this.clinicalExpenseForm.get('treatment').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideFields(hasConsultation) {
        if (hasConsultation === "true") {
            this.toggleForms(this.clinicalExpenseForm, true);
        } else {
            this.outpatientConsultation.setClinicalExpenses(null);
            this.toggleForms(this.clinicalExpenseForm, false);
        }
    }

    toggleForms(form: FormGroup, show: boolean) {
        if(show){
            form.enable({onlySelf: false, emitEvent: false});
        } else {
            form.disable({onlySelf: false, emitEvent: false});
        }
    }

    buildClinicalExpenseItems(clinicalExpenses: ClinicalExpenseDetails[]): FormGroup[] {

        let formGroupArray : FormGroup[] = [];

        if(clinicalExpenses != null && clinicalExpenses.length > 0 ) {
            for(let clinicalExpense of clinicalExpenses) {
                let rowFormGroup = this.fb.group({
                    dateVisit: [clinicalExpense.getDateVisit()],
                    medicalAttention: [clinicalExpense.getMedicalAttention()],
                    medicalAttentionDesc: [clinicalExpense.getMedicalAttentionDesc()],
                    medicalAttentionOthers: [clinicalExpense.getMedicalAttentionOthers()],
                    clinicalExpense: [clinicalExpense.getClinicalExpense()],
                    clinicalExpenseCurrency: [clinicalExpense.getClinicalExpenseCurrency()]
                });
                formGroupArray.push(rowFormGroup);
            }
        }
        else {
            formGroupArray.push(this.createDefaultItems());
        }

        return formGroupArray;
    }

    createDefaultItems(): FormGroup {
        return this.fb.group({
            dateVisit: null,
            medicalAttention: '',
            medicalAttentionDesc: '',
            medicalAttentionOthers: '',
            clinicalExpense: '',
            clinicalExpenseCurrency: this.currency
        });
    }

    addItem(): void {
        let self = this;
        setTimeout(function () {

            let items = self.clinicalExpenseForm.get('items') as FormArray;
            let newItem : FormGroup = self.createDefaultItems();
            items.push(newItem);

            newItem.get('medicalAttention').valueChanges.pipe(takeUntil(self.ngUnsubscribe)).subscribe(data => {
                self.medicalAttentionChanged(data, newItem);
            });

        }, 10);
    }

    removeItem(i) {
        let items = this.clinicalExpenseForm.get('items') as FormArray;
        items.removeAt(i);
        this.clinicalExpenseItems.splice(i, 1);
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 2});
            this.sideMenuService.emitProcess(1, 0);
            let url = this.claim.getCountry().toLowerCase() === 'sgp' ? "/claimform/accidentalMedicalExpenses/medicalCertificates" 
                        : "/claimform/accidentalMedicalExpenses/supportDoc";
            this.router.navigate([url], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        if(super.validateForm(this.outpatientForm)){
            if(this.outpatientConsultation.getHasOutpatientConsultations()){
                return super.validateForm(this.clinicalExpenseForm);
            } else {
                return true;
            }
        }
        return false;
    }

    back() {
        this.router.navigate(["/claimform/accidentalMedicalExpenses/icuDetails"], {
            relativeTo: this.activatedRoute
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGAPageView() {
        let pageStep = this.transactionInfo.getGaPageStep() + ' – Outpatient Consultations';
        let vPath =  this.transactionInfo.getGaVPath() + '/outpatient-consultations';
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': pageStep,
            'vPath': vPath,
            'event': 'vpageview'
        });
    }

}
