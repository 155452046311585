<div class="upload-doc-body">
    <div class="prev-bar">
      <a (click)="goBack()" style="color: #0064c1" class="btn new-btn btn-secondary"><em class="fas fa-arrow-left"></em>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
    </div>
    <div class="col-12">
      <h5>{{ 'claimSection.generalLabels.uploadDocuments.uploadYourDocuments' | translate}}</h5>
      <p>{{ 'claimSection.generalLabels.uploadDocuments.documentsNeeded1' | translate}}&nbsp;{{totalFile}}&nbsp;{{ 'claimSection.generalLabels.uploadDocuments.documentsNeeded2' | translate}}</p>
    </div>

    <app-pa-upload-doc></app-pa-upload-doc>
  
    <div class="submit-bar">
      <abandon-button></abandon-button>
      <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
      <a (click)="goToNext()" routerLink="/claimform/anotherInsurance" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<em class="fas fa-arrow-right"></em></a>
    </div>
    <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>