import {Component, Injector, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {SideMenuService} from '../../services/side-menu.service';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {ClaimService} from "../../services/claim.service";
import {Claim} from "../../model/claim.model";
import {TransactionInfoService} from "../../services/transaction-info.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ClaimTypeItem} from '../../model/claim-type-item.model';
import {ClaimTypes} from '../../model/claim-type.model';
import {ClaimTypeItemHelperService} from "../../services/claim-type-item-helper.service";
import {TransactionInfo} from "../../model/transaction-info.model";
import {ClaimTypeMenu} from "../../model/claim-type-menu.model";
import { StoredDataService } from 'src/app/services/stored-data-service';
import {ClaimProductService} from "../../services/claim-product.service";
import {NavigationMenu} from "../../model/navigation-menu.model";
import { UtilitiesService } from 'src/app/utilities/utilities.service';

declare var $: any;

@Component({
    selector: 'app-sidemenu',
    templateUrl: './sidemenu.component.html',
    styleUrls: ['./sidemenu.component.css']
})
export class SidemenuComponent implements OnInit {

    public ClaimTypeId: number;
    public limit = {ClaimTypeId: null};
    public subMenuList: ClaimTypeMenu[];
    public selectInsuredName: string;
    navigationMenuList: NavigationMenu;
    showClaimDetail = true;
    claim: Claim;
    selectedClaimTypeNumber: number;
    @ViewChild('confirm_delete_claim_type', {static: true}) deleteClaimTypeModal: TemplateRef<any>;
    deleteClaimTypeModalRef: any;
    transactionInfo: TransactionInfo;
    productDescription: string;
    paAccidentDetailsUrl: string;
    private isFinishConfirmTravelPeriod = false;
    private isFinishSelectInsured = false;
    private isFinishAccidentDetails = false;
    private isFinishInjuryDetails = false;
    private isFinishSelectClaim = false;
    private isFinishPaymentAndContact = false;
    private isFinishReviewSummary = false;
    private isFinishConfirmVehicleDetails = false;
    private isFinishConfirmIncidentDetails = false;
    private isFinishGenericClaimDetail = false;
    private isFinishGenericUploadDocument = false;
    private claimService: ClaimService;
    private transactionInfoService: TransactionInfoService;
    private claimTypeItemHelperService: ClaimTypeItemHelperService;
    private storeDataService: StoredDataService;
    private claimProductService: ClaimProductService;

    constructor(private sideMenuService: SideMenuService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private modalService: NgbModal,
                private injector : Injector) {

        this.claimService = this.injector.get(ClaimService);
        this.transactionInfoService = this.injector.get(TransactionInfoService);
        this.claimTypeItemHelperService = this.injector.get(ClaimTypeItemHelperService);
        this.storeDataService = this.injector.get(StoredDataService);
        this.claimProductService = this.injector.get(ClaimProductService);
        this.claim = this.claimService.getClaim();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
        this.paAccidentDetailsUrl = this.claim.isHKGorHGI() ? '/claimform/paDetailsOfAccident' : '/claimform/paAccidentDetails';

        sideMenuService.claimMenuBuilt$.subscribe(
            action => {
                //for claim type detail menu list
                if (action === 'resume') {
                    this.claim = this.claimService.getClaim();
                    this.transactionInfo  = this.transactionInfoService.getTransactionInfo();
                    this.subMenuList =  this.transactionInfo.getNavigationMenu().getClaimDetailMenu();
                    this.navigationMenuList = this.transactionInfo.getNavigationMenu();
                } else {
                    this.subMenuList = this.navigationMenuList.getClaimDetailMenu();
                }
                this.setProductDescriptionDisplay();
            });

        sideMenuService.changeCliamTypeId$.subscribe(
            ClaimTypeId => {
                this.ClaimTypeId = ClaimTypeId;
                this.subMenuList[this.ClaimTypeId]['hidden'] = false;
            });

        sideMenuService.changeSelectInsuredPerson$.subscribe(
            name => {
                this.selectInsuredName = name;
            }
        );

        sideMenuService.changeSelectClaim.subscribe(
            show => {
                if (show) {
                    this.transactionInfo.getNavigationMenu().setClaimDetailsState("true");
                } else {
                    this.transactionInfo.getNavigationMenu().setClaimDetailsState("false");
                }
            }
        );

        sideMenuService.claimTypeSubMenuHide$.subscribe(
            claimType => {
                let submenus = this.subMenuList[claimType['claimTypeId']]['subMenus'];
                //for personal acctident with nature of illnes as 'due to sickness or death by accident'
                if (claimType.claimTypeId == 13 && claimType.subMenuIndex === 0) {
                    if (claimType.value && (claimType.value.toLowerCase() === "death by accident" || claimType.value.toLowerCase() === "death due to sickness")) {
                        for (const menu of submenus) {
                            if(menu.url === "/claimform/personalAccident/followUpTreatment") {
                                menu.hidden = true;
                                menu.complete = false;
                                let claimTravel = this.claim.getClaimTravel();
                                if(claimTravel != null && claimTravel.getDeathAndSeriousBodyInjuryLoss() != null) {
                                    claimTravel.getDeathAndSeriousBodyInjuryLoss().setReturnHkDate(null);
                                    claimTravel.getDeathAndSeriousBodyInjuryLoss().setHasReceiveFollowUpTreament(null);
                                    claimTravel.getDeathAndSeriousBodyInjuryLoss().setLastTreamentDate(null);
                                }
                            }
                        }
                    } else {
                        for (const menu of submenus) {
                            if(menu.url === "/claimform/personalAccident/followUpTreatment" && menu.hidden === true) {
                                menu.hidden = false;
                            }
                        }
                    }
                    this.navigationMenuList['claimDetailMenu'] = this.subMenuList;
                    //for repatriation domestic helper
                } else if(claimType.claimTypeId === 8 && claimType.subMenuIndex === 1){
                    let planCode = this.claim.getPlanCode();
                    let planCodeList = ['F3','F4','M3','','M4','M7','M8','N0','N1','N2','N3','N4','N5','Q3','Q4','T1','T2','U3','U4','BA'];
                    if (claimType.value === "Replacement or Temporary Helper" &&
                        (planCodeList.indexOf(planCode) > -1)
                    ) {
                        for (const menu of submenus) {
                            if(menu.url === "/claimform/repatriation/extraCosts") {
                                menu.hidden = true;
                                menu.complete = false;
                                let claimDomestic = this.claim.getClaimDomesticHelper();
                                if(claimDomestic != null && claimDomestic.getRepatriation() != null) {
                                    claimDomestic.getRepatriation().setHasAgentFeePaid(false);
                                    claimDomestic.getRepatriation().setHasTempHelper(false);
                                    claimDomestic.getRepatriation().setAgentFeePaidAmount(null);
                                    claimDomestic.getRepatriation().setAgentFeePaidCurrency(null);
                                    claimDomestic.getRepatriation().setTempHelperAmount(null);
                                    claimDomestic.getRepatriation().setTempHelperAmountCurrency(null);
                                }
                            }
                        }
                    } else {
                        for (const menu of submenus) {
                            if(menu.url === "/claimform/repatriation/extraCosts" && menu.hidden === true) {
                                menu.hidden = false;
                            }
                        }
                    }
                } else if(claimType.claimTypeIdName == ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_COLLISION] && claimType.subMenuIndex === 1){
                    let motorDetails = this.claim.getClaimMotor();
                    let motorCollissionDetails = motorDetails.getCollission();
                    if (claimType.value === "Driver Details" && (motorCollissionDetails.getWasVehicleParkWhenDamage())) {
                        for (const menu of submenus) {
                            if(menu.url === "/claimform/collission/driverDetails") {
                                menu.hidden = true;
                                menu.complete = false;
                                if(motorDetails != null && motorCollissionDetails != null) {
                                    motorCollissionDetails.setDriverType(null);
                                    motorCollissionDetails.setDriverName(null);
                                    motorCollissionDetails.setDriverAge(null);
                                    motorCollissionDetails.setWasDriverDrunk(false)
                                    motorCollissionDetails.setWhoCauseTheAccident(null);
                                    motorCollissionDetails.setOtherDriverName(null);
                                    motorCollissionDetails.setOtherDriverContactNo(null);
                                    motorCollissionDetails.setContactNumber(null);
                                }
                            }
                        }
                    } else {
                        for (const menu of submenus) {
                            if(menu.url === "/claimform/collission/driverDetails" && menu.hidden === true) {
                                menu.hidden = false;
                            }
                        }
                    }
                } else if(claimType.claimTypeIdName == ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_COLLISION] && claimType.subMenuIndex === 2){
                    let motorDetails = this.claim.getClaimMotor();
                    let coverType = motorDetails.getCoverType();
                    let isCoverNotePreNumbered = !UtilitiesService.isNullOrUndefined(this.claim.getIsCoverNotePreNumbered()) && this.claim.getIsCoverNotePreNumbered() != "";
                    if (claimType.value === "Details of the Damage to Insured Vehicle" && coverType !== 'TF' &&
                        coverType !== 'CO' && !isCoverNotePreNumbered) {
                        for (const menu of submenus) {
                            if(menu.url === "/claimform/collission/vehicleDamage") {
                                menu.hidden = true;
                                menu.complete = false;
                            }
                        }
                    } else {
                        for (const menu of submenus) {
                            if(menu.url === "/claimform/collission/vehicleDamage" && menu.hidden === true) {
                                menu.hidden = false;
                            }
                        }
                    }
                } else if(claimType.value == ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_WATER] ||
                        claimType.value == ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_FIRE] ||
                        claimType.value == ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_VANDALISM] ||
                        claimType.value == ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_BURGLARY] ||
                        claimType.value == ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_WEATHER]) {
                            let temp = claimType.value.split("_");
                            let claimTypeVal = temp[temp.length-1].toLowerCase();
                    for (const menu of submenus) {
                        if(menu.url === "/claimform/" + claimTypeVal + "/homeItems") {
                            menu.hidden = !this.claim.getClaimHome().getHomeDetails().getIsAnyItemDamaged();
                            if(!this.claim.getClaimHome().getHomeDetails().getIsAnyItemDamaged()){
                                menu.complete = true;
                            } else {
                                menu.complete = false;
                            }
                        }
                    }
                } else if((claimType.value == ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES] && !this.claim.isHKGorHGI())
                             || claimType.value == ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL]) {
                    submenus[claimType.subMenuIndex]['hidden'] = !claimType.show;
                    if (!claimType.show) {
                        submenus[claimType.subMenuIndex]['complete'] = false;
                    }
                }

            });

        sideMenuService.claimTypeSubMenuShowByUrl$.subscribe(
            routeData => {
                let routeUrl = routeData.routeUrl;
                let submenus = this.subMenuList[routeData.claimTypeId]['subMenus'];
                for (const menu of submenus) {
                    if (menu.url === routeUrl) {
                        menu.hidden = !routeData.show;
                        menu.complete = !routeData.show;
                    }
                }
         });

        sideMenuService.changeStepComplete$.subscribe(
            finishType => {
                console.log('finish', finishType);
                if (finishType === 'finishGetStarted') {
                    this.navigationMenuList.setGetStartedState("finish");
                } else if (finishType === 'finishConfirmTravelPeriod') {
                    this.navigationMenuList.setConfirmTravelPeriodState("finish");
                    this.isFinishConfirmTravelPeriod = true;
                } else if (finishType === 'finishConfirmVehicleDetails') {
                    this.navigationMenuList.setConfirmTravelPeriodState("finish");
                    this.isFinishConfirmVehicleDetails = true;
                } else if (finishType === 'finishConfirmIncidentDetails') {
                    this.navigationMenuList.setConfirmTravelPeriodState("finish");
                    this.isFinishConfirmIncidentDetails = true;
                } else if (finishType === 'finishSelectInsured') {
                    this.navigationMenuList.setSelectInsuredState("finish");
                    this.isFinishSelectInsured  = true;
                } else if (finishType === 'finishAccidentDetails') {
                    this.navigationMenuList.setAccidentDetailsState("finish");
                    this.isFinishAccidentDetails  = true;
                } else if (finishType === 'finishInjuryDetails') {
                    this.navigationMenuList.setInjuryDetailsState("finish");
                    this.isFinishInjuryDetails  = true;
                } else if (finishType === 'finishDetail') {
                    this.navigationMenuList.setSelectClaimState("finish");
                    this.isFinishSelectClaim = true;
                } else if (finishType === 'finishPayment') {
                    this.navigationMenuList.setPaymentAndContactState("finish");
                    this.isFinishPaymentAndContact = true;
                } else if (finishType === 'finishReview') {
                    this.navigationMenuList.setReviewSummaryState("finish");
                    this.isFinishReviewSummary = true;
                } else if (finishType === 'finishGenericClaimDetail') {
                    this.navigationMenuList.setGenericClaimDetailState('finish');
                    this.isFinishGenericClaimDetail = true;
                } else if (finishType === 'finishGenericUploadDocument') {
                    this.navigationMenuList.setGenericUploadDocumentState('finish');
                    this.isFinishGenericUploadDocument = true;
                }
            }

        );

        sideMenuService.changeClaimDetailComplete$.subscribe(
            claimDetail => {
                this.subMenuList[claimDetail['claimTypeId']]['subMenus'][claimDetail['subMenuIndex']]['complete'] = true;
                let transInfo = this.transactionInfoService.getTransactionInfo();

                if (transInfo.isTravelProduct()) {
                    if (claimDetail.claimTypeId === 3 || claimDetail.claimTypeId === 6 || claimDetail.claimTypeId === 11 ||
                        claimDetail.claimTypeId === 12 || claimDetail.claimTypeId === 14 || claimDetail.claimTypeId === 15 ||
                        claimDetail.claimTypeId === 10) {
                        if (claimDetail.subMenuIndex === 2) {
                            this.subMenuList[claimDetail['claimTypeId']]['complete'] = true;
                        }
                    } else if (claimDetail.claimTypeId === 4 || claimDetail.claimTypeId === 5 || claimDetail.claimTypeId === 13) {
                        if (claimDetail.subMenuIndex === 4) {
                            this.subMenuList[claimDetail['claimTypeId']]['complete'] = true;
                        }
                    } else if (claimDetail.claimTypeId === 9) {
                        if (claimDetail.subMenuIndex === 1) {
                            this.subMenuList[claimDetail['claimTypeId']]['complete'] = true;
                        }
                    } else if (claimDetail.claimTypeId === 1 || claimDetail.claimTypeId === 2 ||
                        claimDetail.claimTypeId === 8 || claimDetail.claimTypeId === 0) {
                        if (claimDetail.subMenuIndex === 3) {
                            this.subMenuList[claimDetail['claimTypeId']]['complete'] = true;
                        }
                    }
                } else if (transInfo.isDomesticHelperProduct()) {
                    if (claimDetail.claimTypeId === 3 || claimDetail.claimTypeId === 4 || claimDetail.claimTypeId === 6 ||
                        claimDetail.claimTypeId === 7 || claimDetail.claimTypeId === 9) {
                        if (claimDetail.subMenuIndex === 1) {
                            this.subMenuList[claimDetail['claimTypeId']]['complete'] = true;
                        }
                    } else if (claimDetail.claimTypeId === 1 || claimDetail.claimTypeId === 8) {
                        if (claimDetail.subMenuIndex === 2) {
                            this.subMenuList[claimDetail['claimTypeId']]['complete'] = true;
                        }
                    } else if (claimDetail.claimTypeId === 0 || claimDetail.claimTypeId === 5) {
                        if (claimDetail.subMenuIndex === 3) {
                            this.subMenuList[claimDetail['claimTypeId']]['complete'] = true;
                        }
                    }
                } else if (transInfo.isMotorProduct()) {
                    if (claimDetail.claimTypeId === 0 || claimDetail.claimTypeId === 2) {
                        if (claimDetail.subMenuIndex === 2) {
                            this.subMenuList[claimDetail['claimTypeId']]['complete'] = true;
                        }
                    }
                }

                this.navigationMenuList['claimDetailMenu'] = this.subMenuList;
                //check if all submenuses are complete
                this.checkClaimTypeDetailsCompletion(this.subMenuList[claimDetail['claimTypeId']], claimDetail.claimTypeId);
            }
        );

        // checck incomplete details for each claim details
        sideMenuService.changeClaimDetailIncomplete$.subscribe(
            claimDetail => {
                this.subMenuList[claimDetail['claimTypeId']]['subMenus'][claimDetail['subMenuIndex']]['complete'] = false;

                if (claimDetail.claimTypeId === 3 || claimDetail.claimTypeId === 6 || claimDetail.claimTypeId === 11 ||
                    claimDetail.claimTypeId === 12 || claimDetail.claimTypeId === 14 || claimDetail.claimTypeId === 15) {
                    if (claimDetail.subMenuIndex === 2) {
                        this.subMenuList[claimDetail['claimTypeId']]['complete'] = false;
                    }
                } else if (claimDetail.claimTypeId === 4 || claimDetail.claimTypeId === 5 ||
                    claimDetail.claimTypeId === 0 || claimDetail.claimTypeId === 13) {
                    if (claimDetail.subMenuIndex === 4) {
                        this.subMenuList[claimDetail['claimTypeId']]['complete'] = false;
                    }
                } else if (claimDetail.claimTypeId === 9 || claimDetail.claimTypeId === 10 ) {
                    if (claimDetail.subMenuIndex === 1) {
                        this.subMenuList[claimDetail['claimTypeId']]['complete'] = false;
                    }
                } else if (claimDetail.claimTypeId === 1 ||  claimDetail.claimTypeId === 2 ||
                    claimDetail.claimTypeId === 8) {
                    if(claimDetail.subMenuIndex === 3) {
                        this.subMenuList[claimDetail['claimTypeId']]['complete'] = false;
                    }
                }

                this.navigationMenuList['claimDetailMenu'] = this.subMenuList;

                //check if submenus have incomplete details
                let submenus = this.subMenuList[claimDetail['claimTypeId']]['subMenus'];
                this.checkClaimTypeDetailsIncomplete(submenus, claimDetail.claimTypeId);
            }
        );

        sideMenuService.validateClaimDetailComplete$.subscribe(
            claimDetail => {

                let submenus = this.subMenuList[claimDetail['claimTypeId']]['subMenus'];

                if(submenus.length > 0) {
                    let subMenuCompleted: number = 0;
                    for (const menu of submenus) {
                        if(menu.complete === true) {
                            subMenuCompleted = subMenuCompleted + 1;
                        }
                    }

                    if (subMenuCompleted == submenus.length) {
                        return true;
                    }
                }
                return false;

            });
    }


    checkClaimTypeDetailsCompletion(claimTypeMenu, claimTypeId) {
        let claimTypeSubMenu = claimTypeMenu.subMenus;
        let subMenuLength = claimTypeSubMenu.length - 1;
        let claimTypeLists: ClaimTypeItem[] = this.transactionInfo.getClaimTypeItemList();
        if (subMenuLength > 0) {
            let completeSubMenu = true;

            for (const menu of claimTypeSubMenu) {
                if (menu.complete == false && menu.hidden == false) {
                    completeSubMenu = false;
                }
            }

            if (completeSubMenu) {
                claimTypeLists.forEach(function (claimTypeItem) {
                    if(claimTypeItem.claimTypeId == claimTypeId){
                        claimTypeItem.completed = true;
                    }
                });
                this.transactionInfoService.getTransactionInfo().setClaimTypeItemList(claimTypeLists);
                claimTypeMenu.complete = true;
            }
        // no submenu for PA HKG, mark as complete
        } else if (this.transactionInfo.isAccidentProduct() && this.claim.isHKGorHGI()) {
            claimTypeLists.forEach(function (claimTypeItem) {
                if(claimTypeItem.claimTypeId == claimTypeId){
                    claimTypeItem.completed = true;
                }
            });
            this.transactionInfoService.getTransactionInfo().setClaimTypeItemList(claimTypeLists);
            claimTypeMenu.complete = true;
        }
    }

    checkClaimTypeDetailsIncomplete(submenus, claimTypeId) {

        let subMenuLength = submenus.length - 1;

        if(subMenuLength > 0) {
            let hasIncompleteDetail: boolean = false;
            //additional check for hidden claim type sub menu - no need to check if complete
            for (const menu of submenus) {
                if(menu.hidden === false && menu.complete === false) {
                    hasIncompleteDetail = true;
                }
            }
            //update claim type completed to false
            if (hasIncompleteDetail) {
                let claimTypeLists: ClaimTypeItem[] = this.transactionInfoService.getTransactionInfo().getClaimTypeItemList();
                claimTypeLists.forEach(function (claimTypeItem) {
                    if(claimTypeItem.claimTypeId == claimTypeId){
                        claimTypeItem.completed = false;
                    }
                });
                this.transactionInfoService.getTransactionInfo().setClaimTypeItemList(claimTypeLists);
            }
        }

    }


    ngOnInit() {
        if (this.transactionInfoService.getTransactionInfo().isAccidentProduct()) {
            this.productDescription = 'claimStatus.productDesc.PAN';
        } else {
            this.setProductDescriptionDisplay();
        }

        this.router.events.subscribe((value) => {

            if (value instanceof NavigationEnd) {
                if (value.url === '/claimform/getStart') {
                    this.navigationMenuList.setGetStartedState("current");
                } else if (value.url === '/claimform/confirmTravelPeriod') {
                    this.navigationMenuList.setConfirmTravelPeriodState("current");
                    this.validateSelectInsuredPeriodState();
                    this.validateSelectClaimState();
                    this.validatePaymentAndContactState();
                    this.validateReviewSummaryState();
                } else if (value.url === '/claimform/confirmVehicleDetails' || value.url === '/claimform/confirmIncidentDetails' || value.url === '/claimform/employerDetails') {
                    this.navigationMenuList.setConfirmTravelPeriodState("current");
                    this.validateSelectClaimState();
                    this.validatePaymentAndContactState();
                    this.validateReviewSummaryState();
                } else if (value.url === '/claimform/selectInsured') {
                    this.navigationMenuList.setSelectInsuredState("current");
                    this.validateConfirmaTravelPeriodState();
                    this.validateAccidentDetailsState();
                    this.validateInjuryDetailsState();
                    this.validateSelectClaimState();
                    this.validatePaymentAndContactState();
                    this.validateReviewSummaryState();
                } else if (value.url === '/claimform/paAccidentDetails' || value.url === '/claimform/paDetailsOfAccident') {
                    this.navigationMenuList.setAccidentDetailsState("current");
                    this.validateSelectInsuredPeriodState();
                    if (value.url === '/claimform/paAccidentDetails') this.validateInjuryDetailsState();
                    this.validateSelectClaimState();
                    this.validatePaymentAndContactState();
                    this.validateReviewSummaryState();
                } else if (value.url === '/claimform/paInjuryDetails') {
                    this.navigationMenuList.setInjuryDetailsState("current");
                    this.validateSelectInsuredPeriodState();
                    this.validateAccidentDetailsState();
                    this.validateSelectClaimState();
                    this.validatePaymentAndContactState();
                    this.validateReviewSummaryState();
                } else if (value.url === '/claimform/othersHome/details' 
                            && this.claim.getClaimHome() 
                            && this.claim.getClaimHome().getPlaceOfIncident() !== 'home' 
                            && this.claim.getClaimHome().getHasHSRRisk()) {
                    this.navigationMenuList.setSelectClaimState("current");
                } else  if (value.url === '/claimform/claimDetail') {
                    this.navigationMenuList.setSelectClaimState("current");
                    this.validateConfirmaTravelPeriodState();
                    this.validateSelectInsuredPeriodState();
                    this.validateAccidentDetailsState();
                    this.validateInjuryDetailsState();
                    this.validatePaymentAndContactState();
                    this.validateReviewSummaryState();
                } else  if (value.url.indexOf('/claimform/paymentAndContact/') !== -1) {
                    this.navigationMenuList.setPaymentAndContactState("current");
                    this.validateConfirmaTravelPeriodState();
                    this.validateSelectInsuredPeriodState();
                    this.validateAccidentDetailsState();
                    this.validateInjuryDetailsState();
                    this.validateSelectClaimState();
                    this.validateGenericClaimDetailState();
                    this.validatGenericUploadDocumentState();
                    this.validateReviewSummaryState();
                }  else  if (value.url === '/claimform/summary') {
                    this.navigationMenuList.setReviewSummaryState("current");
                    this.validateConfirmaTravelPeriodState();
                    this.validateSelectInsuredPeriodState();
                    this.validateAccidentDetailsState();
                    this.validateInjuryDetailsState();
                    this.validateSelectClaimState();
                    this.validateGenericClaimDetailState();
                    this.validatGenericUploadDocumentState();
                    this.validatePaymentAndContactState();
                }  else  if (value.url === '/claimform/genericClaimDetail') {
                    this.navigationMenuList.setGenericClaimDetailState("current");
                    this.validatGenericUploadDocumentState();
                    this.validatePaymentAndContactState();
                    this.validateReviewSummaryState();
                }  else  if (value.url === '/claimform/genericUploadDocument') {
                    this.navigationMenuList.setGenericUploadDocumentState("current");
                    this.validateGenericClaimDetailState();
                    this.validatePaymentAndContactState();
                    this.validateReviewSummaryState();
                } else {
                    this.validateConfirmaTravelPeriodState();
                    this.validateSelectInsuredPeriodState();
                    this.validateAccidentDetailsState();
                    this.validateInjuryDetailsState();
                    this.validateSelectClaimState();
                    this.validateGenericClaimDetailState();
                    this.validatGenericUploadDocumentState();
                    this.validatePaymentAndContactState();
                }
                this.transactionInfo.setNavigationMenu(this.navigationMenuList);
                this.storeDataService.storeSession(); // stored the storeNavigationMenuState status
            }

        });
        this.showClaimDetail = true;

        //for whole navigation menu list
        this.navigationMenuList = this.transactionInfoService.getTransactionInfo().getNavigationMenu();

        //for claim type detail menu list
        this.subMenuList = this.navigationMenuList.getClaimDetailMenu();
        if (!this.transactionInfoService.getTransactionInfo().getIsTransactionOrigin()
                && this.navigationMenuList.getGetStartedState() != "finish" ) {
            this.navigationMenuList.setGetStartedState("current");
        }

        this.transactionInfo  = this.transactionInfoService.getTransactionInfo();

        //validate navigation menu steps if finished
        this.validateFinishSteps();
    }

    setProductDescriptionDisplay() {
        if (this.isNotNullOrUndefinedStr(this.claim.getIsCoverNotePreNumbered())) {
            this.productDescription = this.claim.getIsCoverNotePreNumbered().startsWith('CN') ?
                'motorClaim.homePage.coverNote' : 'claimStatus.productDesc.MVA';
        } else {
            let productType = this.isNotNullOrUndefinedStr(this.claim.getProduct()) ? this.claim.getProduct() : 
                this.transactionInfoService.getTransactionInfo().getProduct().getProductType();
            if (this.isNotNullOrUndefinedStr(productType)) {
                let catalogueCode = this.isNotNullOrUndefinedStr(this.claim.getCatalogueCode()) ? this.claim.getCatalogueCode() :
                    this.transactionInfoService.getTransactionInfo().getCatalogueCode();
                this.productDescription =  this.claimProductService.getProductLabel(productType, catalogueCode);
            } else {
                this.productDescription = 'pageSummaryClaim.policy';
            }
        }
    }

    validateConfirmaTravelPeriodState()  {
        if (this.isFinishConfirmTravelPeriod && this.transactionInfo.isTravelProduct())  {
            this.navigationMenuList.setConfirmTravelPeriodState("finish");
        } else if (this.isFinishConfirmVehicleDetails && this.transactionInfo.isMotorProduct()) {
            this.navigationMenuList.setConfirmTravelPeriodState("finish");
        } else if (this.isFinishConfirmIncidentDetails && (this.transactionInfo.isHomeProduct() || this.transactionInfo.isEmployeeCompensationProduct())) {
            this.navigationMenuList.setConfirmTravelPeriodState("finish");
        } else {
            this.navigationMenuList.setConfirmTravelPeriodState("not started");
        }
    }

    validateSelectInsuredPeriodState()  {
        if (this.isFinishSelectInsured)  {
            this.navigationMenuList.setSelectInsuredState("finish");
        } else {
            this.navigationMenuList.setSelectInsuredState("not started");
        }
    }

    validateAccidentDetailsState()  {
        if (this.isFinishAccidentDetails)  {
            this.navigationMenuList.setAccidentDetailsState("finish");
        } else {
            this.navigationMenuList.setAccidentDetailsState("not started");
        }
    }

    validateInjuryDetailsState()  {
        if (this.isFinishInjuryDetails)  {
            this.navigationMenuList.setInjuryDetailsState("finish");
        } else {
            this.navigationMenuList.setInjuryDetailsState("not started");
        }
    }

    validateSelectClaimState()  {
       if (this.isFinishSelectClaim)  {
            this.navigationMenuList.setSelectClaimState("finish");
        } else {
            this.navigationMenuList.setSelectClaimState("not started");
        }
    }

    validateGenericClaimDetailState() {
        if (this.isFinishGenericClaimDetail) {
            this.navigationMenuList.setGenericClaimDetailState('finish');
        } else {
            this.navigationMenuList.setGenericClaimDetailState('not started');
        }
    }

    validatGenericUploadDocumentState() {
        if (this.isFinishGenericUploadDocument) {
            this.navigationMenuList.setGenericUploadDocumentState('finish');
        } else {
            this.navigationMenuList.setGenericUploadDocumentState('not started');
        }
    }

    validatePaymentAndContactState()  {
        if (this.isFinishPaymentAndContact)  {
            this.navigationMenuList.setPaymentAndContactState("finish");
        } else {
            this.navigationMenuList.setPaymentAndContactState("not started");
        }
    }

    validateReviewSummaryState()  {
        if (this.isFinishReviewSummary)  {
            this.navigationMenuList.setReviewSummaryState("finish");
        } else {
            this.navigationMenuList.setReviewSummaryState("not started");
        }
    }

    validateFinishSteps() {

        if (this.navigationMenuList.getConfirmTravelPeriodState() == "finish" && this.transactionInfo.isTravelProduct())  {
            this.isFinishConfirmTravelPeriod = true;
        }

        if (this.navigationMenuList.getConfirmTravelPeriodState() == "finish" && this.transactionInfo.isMotorProduct())  {
            this.isFinishConfirmVehicleDetails = true;
        }

        if (this.navigationMenuList.getConfirmTravelPeriodState() == "finish" && (this.transactionInfo.isHomeProduct() || this.transactionInfo.isEmployeeCompensationProduct()))  {
            this.isFinishConfirmIncidentDetails = true;
        }

        if (this.navigationMenuList.getSelectClaimState() == "finish") {
           this.isFinishSelectClaim = true;
        }

        if(this.navigationMenuList.getSelectInsuredState() == "finish") {
            this.isFinishSelectInsured = true;
        }

        if(this.navigationMenuList.getAccidentDetailsState() == "finish") {
            this.isFinishAccidentDetails = true;
        }

        if(this.navigationMenuList.getInjuryDetailsState() == "finish") {
            this.isFinishInjuryDetails = true;
        }

        if(this.navigationMenuList.getGenericClaimDetailState() == "finish") {
            this.isFinishGenericClaimDetail = true;
        }

        if(this.navigationMenuList.getGenericUploadDocumentState() == "finish") {
            this.isFinishGenericUploadDocument = true;
        }

        if(this.navigationMenuList.getPaymentAndContactState() == "finish"){
            this.isFinishPaymentAndContact = true;
        }

        if(this.navigationMenuList.getReviewSummaryState() == "finish") {
            this.isFinishReviewSummary = true;
        }

    }

    ngAfterViewInit() {

    }

    open(content, claimTypeNumber) {
        $('.menu-btn').toggleClass('opened');
        $('.container-left').toggleClass('opened');
        this.selectedClaimTypeNumber = claimTypeNumber;
        this.deleteClaimTypeModalRef = this.modalService.open(this.deleteClaimTypeModal, {size: 'sm',
            backdrop: 'static',
            keyboard: false
        });

    }

    deleteClaimType(claimTypeIndex) {
        //navigate to proper screen
        let selectedClaimTypeList: string[] = this.claim.getSelectedClaimTypesList();
        if(selectedClaimTypeList.length == 1){
            this.showClaimDetail = true;
            this.router.navigate(["/claimform/claimDetail"], {
                relativeTo: this.activatedRoute
            });
        } else if (selectedClaimTypeList.length > 1) {
            this.showClaimDetail = false;
            this.router.navigate(["/claimform/moreClaimType"], {
                relativeTo: this.activatedRoute
            });
        }
        this.claimTypeItemHelperService.deleteClaimType(claimTypeIndex);
        this.deleteClaimTypeModalRef.close();
    }

    isNotNullOrUndefinedStr(val: string){
        return !UtilitiesService.isNullOrUndefined(val) && val != '';
    }

    closeMenu() {
        if ($('.menu-btn').is(':visible')) {
            $('.menu-btn').removeClass('opened');
            $('.container-left').removeClass('opened');
        }
    }
}
