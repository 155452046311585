<div>
    <div *ngIf="!claim.isHKGorHGI()" class="prev-bar">
        <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><em class="fas fa-arrow-left"></em> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
    </div>
    <div class="col-12">
        <h5>{{ 'paClaim.hospitalCash.title' | translate }}</h5>
        <br>
    </div>
    <div class="row col-12">
        <div class="col-xl-9 col-lg-12">
            <form [formGroup]="hospitalCashForm">
              <div formArrayName="items" *ngFor="let item of hospitalCashForm.get('items')['controls']; let i = index;">
                <div [formGroupName]="i">
                  <div class="form-group click-more-content">
                    <label>{{'paClaim.common.hospitalExpenseTitle' | translate}} &nbsp; {{i+1}}</label><button [hidden]="i==0" class="removeBtn" (click)="removeItem(i)">{{ 'claimSection.generalLabels.generalButtonLabels.remove' | translate}}</button><BR>
                
                      <qnect-ui [fieldId]="'PA_HOSPITAL_NAME'"
                      [frmArrayGroup]="hospitalCashForm"
                      [frmArrayName]="'items'"
                      frmArrayIndex="{{i}}"
                      name="hospitalName"></qnect-ui>
    
                      <qnect-ui [fieldId]="'PA_DATE_ADMISSION'"
                      [frmArrayGroup]="hospitalCashForm"
                      [frmArrayName]="'items'"
                      frmArrayIndex="{{i}}"
                      [dateRangeValidator]="getDatesForValidation(i, 'a')"
                      [claimsDateValidator]="claimPA.getAccidentDetails().getAccidentDate()"
                      name="dateAdmission"></qnect-ui>
    
                      <qnect-ui [fieldId]="'PA_DATE_DISCHARGE'"
                      [frmArrayGroup]="hospitalCashForm"
                      [frmArrayName]="'items'"
                      frmArrayIndex="{{i}}"
                      [dateRangeValidator]="getDatesForValidation(i, 'd')"
                      name="dateDischarge"></qnect-ui>
    
                      <qnect-ui [fieldId]="'PA_HOSPITAL_EXPENSE'"
                      [frmArrayGroup]="hospitalCashForm"
                      [frmArrayName]="'items'"
                      frmArrayIndex="{{i}}"
                      name="hospitalExpense"></qnect-ui>
                  </div>
                  <div class="col-xl-2 col-lg-12">
                      &nbsp;
                  </div>
                </div>
              </div>
            </form>
            <div class="addFile" (click)="addItem()">
              <em class="fas fa-plus"></em>
              <span>{{ 'paClaim.accidentalMedicalExpenses.addVisitDetails' | translate}}</span>
            </div>
          </div>
        <div class="col-lg-3 col-xl-3 col-md-12">
            &nbsp;
        </div>
        <div class="row col-12" *ngIf="claim.getProduct() === 'PAM'">
            <div class="col-lg-3 col-xl-3 col-md-12">
                &nbsp;
            </div>
            <div class="col-xl-12 col-lg-12">
              <div class="form-group">
                <qnect-ui #PA_HOSPITALCASH_REQUIREDSURGERY [fieldId]="'PA_HOSPITALCASH_REQUIREDSURGERY'" [formGroup]="hospitalCashForm" name="requiredSurgery"></qnect-ui>
              </div>
            </div>
          </div>
    </div>
    <div *ngIf="!claim.isHKGorHGI()" class="submit-bar">
        <abandon-button></abandon-button>
        <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
        <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<em class="fas fa-arrow-right"></em></button>
    </div>
    <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>