import {Component, Injector, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {SideMenuService} from "../../services/side-menu.service";
import {TransactionInfoService} from "../../services/transaction-info.service";
import {TransactionInfo} from "../../model/transaction-info.model";
import {ClaimsBaseComponent} from "../claim-base.component";
import {ClaimService} from "../../services/claim.service";
import {takeUntil} from "rxjs";
import {IntegrationService} from "../../services/integrationservice";
import {Notifications} from "../../utilities/components/notification-messages/notifications.model";
import {Product} from "../../model/product.model";
import {PolicyAuthJson} from "../../model/policy.auth-model";
import {IntegrationRiskJson} from "../../model/integration-risk-data";
import {Claim} from "../../model/claim.model";
import { PolicyInfo } from "src/app/model/policy-info.model";
import {ClaimProductService} from "../../services/claim-product.service";
import {GoogleAnalyticsService} from "../../services/google-analytics.service";
import {InsuredPerson} from '../../model/insured-person.model';
import { PolicyVerifyForm } from "src/app/model/policy-verify-form.model";
import { DateService } from "src/app/ui/ui-datepicker/date.service";
import { DocumentHelperService } from "src/app/services/document-helper.service";
import { UtilitiesService } from "src/app/utilities/utilities.service";
import { UiPopoverModel } from "src/app/ui/ui.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PageService } from "src/app/services/page.service";

declare var $: any;

@Component({
    selector: 'app-get-started',
    templateUrl: './get-started.component.html',
    styleUrls: ['./get-started.component.css']
})
export class GetStartedComponent extends ClaimsBaseComponent implements OnInit {
    policyAuthenticationModel: FormGroup;
    showTotalBox = false;
    notifications: Notifications = new Notifications();
    transactionInfo : TransactionInfo;
    authMethod: string;
    isValidForAuthentication: boolean = false;
    policyInfoList: PolicyInfo[] = [];
    isSameProduct: boolean = false;
    uiPopover: UiPopoverModel = new UiPopoverModel();
    entryUrl: string;
    policyHolder: string

    @ViewChild('acknowledgement_modal', {static: true}) private acknowledgementModal: TemplateRef<any>;

    private sideMenuService: SideMenuService;
    private integrationService: IntegrationService;
    private transactionInfoService : TransactionInfoService;
    private claimService: ClaimService;
    private claimProductService: ClaimProductService;
    private googleAnalyticsService: GoogleAnalyticsService;
    private dateService: DateService;
    private documentHelperService : DocumentHelperService;

    constructor(private fb: FormBuilder,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private modalService: NgbModal,
                private pageService : PageService,
                private injector : Injector) {

        super(injector);

        //Services
        this.sideMenuService = this.injector.get(SideMenuService);
        this.integrationService = this.injector.get(IntegrationService);
        this.transactionInfoService = this.injector.get(TransactionInfoService);
        this.claimService = this.injector.get(ClaimService);
        this.claimProductService = this.injector.get(ClaimProductService);
        this.googleAnalyticsService = this.injector.get(GoogleAnalyticsService);
        this.dateService = this.injector.get(DateService);
        this.documentHelperService = this.injector.get(DocumentHelperService);

        this.notifications.showPreamble = false;
        this.transactionInfo  = this.transactionInfoService.getTransactionInfo();
        this.transactionInfo.setIsFromGeneric(this.transactionInfo.getPolicyType() === "generic");
        this.routingForGeneric();
        this.isValidForAuthentication = true;
    }


    ngOnInit() {

        //dataLayer for GA
        this.pushGAAuthView();
        this.showPopover();

        this.policyAuthenticationModel = this.fb.group({
            name: [this.transactionInfo.getName()],
            dateOfBirth: [this.transactionInfo.getDateOfBirth()],
            idNumber: [this.transactionInfo.getAuthIdNumber()]
           // hkidPassportNumber: [this.transactionInfo.getHkidPassportNumber()]
        });

        //set default auth method when transaction info dob and name are empty
        this.authMethod = "fullname";

        this.pageService.getEntryUrl().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
            entryUrl => { this.entryUrl = entryUrl;}
        );
    }

    ngAfterViewInit() {

        this.policyAuthenticationModel.valueChanges.subscribe(data => {

               this.transactionInfo.setName(this.policyAuthenticationModel.get('name').value);
               this.transactionInfo.setDateOfBirth(super.getValueInComponent('dateOfBirth'));
               this.transactionInfo.setAuthIdNumber(this.policyAuthenticationModel.get('idNumber').value);
               this.isValidForAuthentication  = true;
        });

        let self = this;
        setTimeout(function () {
           let dob = self.transactionInfo.getDateOfBirth();
            if (dob !== undefined  && dob !== null) {
                $('#birthDiv').addClass('active show');
                $('#nameDiv').removeClass('active show');
            } else {
                $('#nameDiv').addClass('active show');
                $('#birthDiv').removeClass('active show');
            }

            if ($('#nameDiv').hasClass('active show') ||  $('#helperNameDiv').hasClass('active show') ||
                 $('#holderNameDiv').hasClass('active show')) {
                self.enableFullNameField();
            } else if ($('#birthDiv').hasClass('active show')) {
                self.enableDOBField();
            } else if ($('#hkidDiv').hasClass('active show') || $('#regNumberDiv').hasClass('active show')) {
                ////self.enableHkidPassportField();
                self.enableIdNumber();
            }

        }, 100);

        //routed from resume email (save for later)
        if (this.transactionInfo.getAction() === TransactionInfo.AUTO_SAVE_RESUME) {
            this.transactionInfo.getNavigationMenu().setGetStartedState("current");
        }

    }

    routingForGeneric() {
        let productTypes = this.transactionInfo.getProductTypes();
        if ((productTypes?.length > 1 || this.transactionInfo.isHomeProduct()) &&
            this.transactionInfo.getRealCountry() === "HKG") {
                let p1: Product = new Product();
                p1.setProductType(productTypes[0]);
                let p2: Product = new Product();
                p2.setProductType(productTypes[1]);
                if (p1.getProductTypeDesc() === p2.getProductTypeDesc() && !this.transactionInfo.isResidentialFire()) {
                    this.transactionInfo.setProduct(p1);
                    this.isSameProduct = true;
                } else {
                    this.transactionInfo.setProductByString('');
                }
        }

        // to update GTM id if originally from generic then route to existing product for hkg only
        if(!this.transactionInfo.isGenericClaim() && this.transactionInfo.getRealCountry() === "HKG") {
            this.googleAnalyticsService.setUpGoogleAnalytics();
        }
    }

    resetFields(fieldName: string) {
        if (fieldName == 'fullname')  {
            this.enableFullNameField();
            this.authMethod = "fullname";
            this.transactionInfo.setName(this.policyAuthenticationModel.get('name').value);
        } else if (fieldName  == 'dateofbirth') {
            this.enableDOBField();
            this.authMethod = "dob";
            this.transactionInfo.setDateOfBirth(super.getValueInComponent('dateOfBirth'));
        } else if (fieldName == 'idNumber') {
            this.enableIdNumber();
            this.authMethod = "idnumber";
            this.transactionInfo.setAuthIdNumber(super.getValueInComponent('idNumber'));
        }
    }

    enableFullNameField() {
        this.policyAuthenticationModel.get('name').enable({onlySelf: false, emitEvent: false});
        this.policyAuthenticationModel.get('dateOfBirth').disable({onlySelf: false, emitEvent: false});
        this.policyAuthenticationModel.get('idNumber').disable({onlySelf: false, emitEvent: false});
    }

    enableDOBField() {
        this.policyAuthenticationModel.get('dateOfBirth').enable({onlySelf: false, emitEvent: false});
        this.policyAuthenticationModel.get('name').disable({onlySelf: false, emitEvent: false});
        this.policyAuthenticationModel.get('idNumber').disable({onlySelf: false, emitEvent: false});
    }

    enableIdNumber() {
        this.policyAuthenticationModel.get('idNumber').enable({onlySelf: false, emitEvent: false});
        this.policyAuthenticationModel.get('name').disable({onlySelf: false, emitEvent: false});
        this.policyAuthenticationModel.get('dateOfBirth').disable({onlySelf: false, emitEvent: false});
    }


    goToNext() {
        let policyNumber = this.claim.getPolicyNumber();
        let country = this.claim.getCountry();
        let name: string;
        let dateOfBirth: Date;
        let idNumber: string;
        let isCoverNotePreNumbered = this.claim.getIsCoverNotePreNumbered();

        //save in transinfo base on authentication method
        let transInfo = this.transactionInfo;
        if ((transInfo.isTravelProduct() && transInfo.getRealCountry() === "HKG") || transInfo.isDomesticHelperProduct()) {
          if (this.authMethod.toLowerCase() == "fullname") {
            name = transInfo.getName();
            dateOfBirth = null;
            //hkidPassport = "";
            idNumber = "";
            transInfo.setDateOfBirth(dateOfBirth);
          } else if (this.authMethod.toLowerCase() == "dob") {
            dateOfBirth = transInfo.getDateOfBirth();
            name = "";
            //hkidPassport = "";
            idNumber = "";
            transInfo.setName(name);
          } else if (this.authMethod.toLowerCase() == "idnumber") {
            idNumber = transInfo.getAuthIdNumber();
            name = "";
            dateOfBirth = null;
            transInfo.setAuthIdNumber(idNumber);
          }
        } else {
           name = transInfo.getName();
           idNumber = transInfo.getAuthIdNumber();
        }

        this.transactionInfoService.setTransactionInfo(transInfo);

        if (super.validateForm(this.policyAuthenticationModel) && this.isValidForAuthentication) {
            this.notifications.clearMessages();
            this.isValidForAuthentication  = false;

            if (policyNumber && policyNumber != 'tst1234') {

                let policyType = this.transactionInfo.getPolicyType();
                let dateOfLoss = this.claim.getDateOfLoss();
                let loggingId = this.transactionInfo.getLoggingId();

                let policyVerifyForm: PolicyVerifyForm = new PolicyVerifyForm();
                policyVerifyForm.setCountry(country);
                policyVerifyForm.setPolicyNumber(policyNumber.toUpperCase());
                policyVerifyForm.setPolicyType(policyType);
                policyVerifyForm.setLoggingId(loggingId);
                if (this.isNotNullOrUndefinedStr(name)) {
                    policyVerifyForm.setName(name);
                }
                if (this.isNotNullOrUndefined(dateOfBirth)) {
                    policyVerifyForm.setDateOfBirth(this.dateService.dateToString(dateOfBirth, "dd/MM/yyyy"));
                }
                if (this.isNotNullOrUndefinedStr(idNumber)) {
                    policyVerifyForm.setIdNumber(idNumber);
                }
                if (this.isNotNullOrUndefined(dateOfLoss)) {
                    policyVerifyForm.setDateOfLoss(this.dateService.dateToString(dateOfLoss, "dd/MM/yyyy"));
                }
                if (this.isNotNullOrUndefinedStr(isCoverNotePreNumbered)) {
                    policyVerifyForm.setIsCoverNotePreNumbered(isCoverNotePreNumbered);
                }
                if (this.transactionInfo.getAction() === TransactionInfo.AUTO_SAVE_RESUME) {
                    policyVerifyForm.setAutoSaveId(this.transactionInfoService.getTransactionInfo().getAutoSaveId());
                }

                // if fails verification in p400, bypass authentication for motor pre-numbered policy and cover note to allow lodgement
                // CNV means cover note verified in p400
               if (this.isNotNullOrUndefinedStr(isCoverNotePreNumbered)) {
                   this.verifyCoverNote(policyVerifyForm);
               } else {
                   this.verifyPolicy(policyVerifyForm);
               }

            } else {

                this.testData();

                this.sideMenuService.emitProcess(1, 0);
                this.sideMenuService.emitComplete('finishGetStarted');
                if (this.transactionInfo.isTravelProduct()) {
                    this.router.navigate(["/claimform/confirmTravelPeriod"], {
                        relativeTo: this.activatedRoute
                    });
                } else if (this.transactionInfo.isDomesticHelperProduct() || 
                    this.transactionInfo.isAccidentProduct()) {
                    this.router.navigate(["/claimform/selectInsured"], {
                        relativeTo: this.activatedRoute
                    });
                } else if (this.transactionInfo.isMotorProduct()) {
                    this.router.navigate(["/claimform/confirmVehicleDetails"], {
                        relativeTo: this.activatedRoute
                    });
                } else if (this.transactionInfo.isHomeProduct()) {
                    this.router.navigate(["/claimform/confirmIncidentDetails"], {
                        relativeTo: this.activatedRoute
                    });
                } else  {
                    this.router.navigate(["/claimform/genericClaimDetail"], {
                        relativeTo: this.activatedRoute
                    });
                }
            }
        }

    }

    verifyPolicy(policyVerifyForm: PolicyVerifyForm) {
        this.integrationService.verifyPolicyNumber(policyVerifyForm)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (policyAuth: PolicyAuthJson) => {
                    if (policyAuth.getErrorStr() == null || policyAuth.getErrorStr() == '') {

                        if (this.transactionInfo.getAction() === TransactionInfo.AUTO_SAVE_RESUME) {
                            this.resumeDetails(policyAuth, this.transactionInfo.getIntegrationToken());
                        }

                        this.transactionInfoService.setupTransaction(policyAuth, this.claim);
                        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
                        this.sideMenuService.emitProcess(1, 0);
                        this.sideMenuService.emitComplete('finishGetStarted');

                        if (this.transactionInfo.isTravelProduct()) {
                            this.router.navigate(["/claimform/confirmTravelPeriod"], {
                                relativeTo: this.activatedRoute
                            });
                        } else if (this.transactionInfo.isDomesticHelperProduct()) {
                            let policyHolderName = this.claim.getPolicyHolderName();
                            this.sideMenuService.emitSelectInsured(policyHolderName);
                            this.router.navigate(["/claimform/selectInsured"], {
                                relativeTo: this.activatedRoute
                            });
                        } else if (this.transactionInfo.isMotorProduct()) {
                            let policyHolderName = this.claim.getPolicyHolderName();
                            this.sideMenuService.emitSelectInsured(policyHolderName);
                            this.router.navigate(["/claimform/confirmVehicleDetails"], {
                                relativeTo: this.activatedRoute
                            });
                        } else if (this.transactionInfo.isHomeProduct()) {
                            this.router.navigate(["/claimform/confirmIncidentDetails"], {
                                relativeTo: this.activatedRoute
                            });
                        } else if (this.transactionInfo.isEmployeeCompensationProduct()) {
                            this.router.navigate(["/claimform/employerDetails"], {
                                relativeTo: this.activatedRoute
                            });
                        } else if (this.transactionInfo.isAccidentProduct()) {
                            this.policyHolder = this.claim.getClaimantName() ? this.claim.getClaimantName() : this.claim.getPolicyHolderName();
                            this.sideMenuService.emitSelectInsured(this.policyHolder);
                            if (this.claim.isHKGorHGI()) {
                                this.modalService.open(this.acknowledgementModal, {size: 'md', backdrop: 'static', keyboard: false});
                            } else {
                                this.router.navigate(['/claimform/selectInsured'], {
                                    relativeTo: this.activatedRoute
                                });
                            }
                        } else if (this.transactionInfo.isGenericClaim()) {
                            if (["HKG", "HGI"].indexOf(this.claim.getCountry()) !== -1 && this.claim.getPolicyRisks()?.length > 1 &&
                                this.claim.getPolicyRisks().find(riskItem =>
                                TransactionInfo.HK_EC_RISKS.indexOf(riskItem.getRiskType()) !== -1)) {
                                this.router.navigate(["/claimform/genericSelectClaim"], {
                                    relativeTo: this.activatedRoute
                                });
                            } else {
                                this.router.navigate(["/claimform/genericClaimDetail"], {
                                    relativeTo: this.activatedRoute
                                });
                            }
                        } else {
                            const errorMessage1 = "error_messages.notificationsErrMsg.serverErrVerifyPolicy";
                            this.notifications.addErrorMessage(errorMessage1);
                            throw new Error("PLCYERR - Server Error verifying policy : Invalid product");
                        }

                        // to update GTM id if originally from generic then routed to existing product
                        if (this.transactionInfo.getIsFromGeneric() && this.transactionInfo.getPolicyType() !== 'generic') {
                            this.googleAnalyticsService.setUpGoogleAnalytics();
                        }

                    }
                    else if(policyAuth.getErrorCode() == 'P02'){
                        const policyNumberLockedMsg = "error_messages.notificationsErrMsg.policyNumberLocked";
                        this.notifications.addErrorMessage(policyNumberLockedMsg);
                        console.log(policyAuth);
                        throw new Error("PLCYERR - Error verifying policy");
                    }  else{
                        const errorMessage1 = "error_messages.notificationsErrMsg.authenticationMisMatch";
                        this.notifications.addErrorMessage(errorMessage1);
                        console.log(policyAuth);
                        throw new Error("PLCYERR - Error verifying policy");
                    }
                },
                error: (err: any) => {
                    const errorMessage1 = "error_messages.notificationsErrMsg.serverErrVerifyPolicy";
                    this.notifications.addErrorMessage(errorMessage1);
                    throw new Error("PLCYERR - Server Error verifying policy : " + err);
                }
            });

    }

    verifyCoverNote(policyVerifyForm) {

        this.integrationService.verifyCoverNote(policyVerifyForm)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (policyAuth: PolicyAuthJson) => {
                    if (policyAuth.getErrorStr() == null || policyAuth.getErrorStr() == '') {

                        console.log("Success auto verifying cover note");
                        if (this.transactionInfo.getAction() === TransactionInfo.AUTO_SAVE_RESUME) {
                           this.resumeDetails(policyAuth, this.transactionInfo.getIntegrationToken());
                        }

                        this.transactionInfoService.setupTransaction(policyAuth, this.claim);

                        this.claim.setPolicyHolderName(this.policyAuthenticationModel.value.name);
                        let claimMotor  = this.claim.getClaimMotor();
                        claimMotor.setVehicleNumber(this.policyAuthenticationModel.value.idNumber);

                        this.sideMenuService.emitProcess(1, 0);
                        this.sideMenuService.emitComplete('finishGetStarted');

                        let policyHolderName = this.claim.getPolicyHolderName();
                        this.sideMenuService.emitSelectInsured(policyHolderName);
                        this.router.navigate(["/claimform/confirmVehicleDetails"], {
                            relativeTo: this.activatedRoute
                        });

                    } else {
                        const errorMessage1 = "error_messages.notificationsErrMsg.authenticationMisMatch";
                        this.notifications.addErrorMessage(errorMessage1);
                        console.log(policyAuth);
                        throw new Error("PLCYERR - Error verifying policy");
                    }
                },
                error: (err: any) => {
                    const errorMessage1 = "error_messages.notificationsErrMsg.serverErrVerifyPolicy";
                    this.notifications.addErrorMessage(errorMessage1);
                    throw new Error("PLCYERR - Server Error verifying policy : " + err);
                }
            });
    }

    resumeDetails(policyAuth, integToken) {
        let claim: Claim = Object.assign(new Claim(), policyAuth.getClaim());
        this.claimService.setClaim(Claim.jsonConvertClaim(claim));
        this.transactionInfoService.setupAutoSaveTransactionAfterAuth(policyAuth, this.claimService.getClaim(), integToken);

        // repopulate claim types from autosave
        const claimTypeList : string[] = this.claimService.getClaim().getSelectedClaimTypesList();
        for (let claimType of claimTypeList ) {
            this.documentHelperService.addDocumentFormBuilder(claimType);
        }

        // Tell the side menu component to reget the claims details menu
        this.sideMenuService.emitClaimMenuDone('resume');
    }

    showPopover() {
        if (this.transactionInfo.isGenericClaim()) {
            this.uiPopover.icon = "hits";
            if (this.transactionInfo.isGroupProduct()) {
                this.uiPopover.content =  "pageGetStarted.body.genericToolTipSgpGroupProduct";
            } else {
                this.uiPopover.content =  "pageGetStarted.body.genericToolTipHkg";
            }
        }
    }

    acknowledge() {
        this.modalService.dismissAll();
        if (this.transactionInfo.hasSingleInsured()) {
            this.router.navigate(["/claimform/paDetailsOfAccident"], { relativeTo: this.activatedRoute });
        } else {
            this.router.navigate(["/claimform/selectInsured"], { relativeTo: this.activatedRoute });
        }
    }

    testData() {

        // Test Data
        const integrationData = new PolicyAuthJson();
        integrationData.setCountry(this.transactionInfoService.getTransactionInfo().getCountry());
        integrationData.setProductType(this.transactionInfoService.getTransactionInfo().getProduct().getProductType());
        integrationData.setPolicyNumber('');
        integrationData.setClientName('');

        const riskJson: IntegrationRiskJson = new IntegrationRiskJson();
        riskJson.setRiskNumber(1);
        riskJson.setRiskClass('PTR');

        let insuredPersonsList = riskJson.getInsuredPersonsList();
        let insured1: InsuredPerson = new InsuredPerson();
        insured1.setInsuredName("Mike Pater");
        insuredPersonsList.push(insured1);
        let insured2: InsuredPerson = new InsuredPerson();
        insured2.setInsuredName("Mystery Guest");
        insuredPersonsList.push(insured2);

        const riskList: IntegrationRiskJson[] = [];
        riskList.push(riskJson);
        integrationData.setIntegrationRiskList(riskList);

        // Set up what type of transaction it is and other data
        const claim: Claim = this.claimService.getClaim();
        this.transactionInfoService.setupTransaction(integrationData, claim);

        const country: string = this.transactionInfoService.getTransactionInfo().getCountry();
        this.router.navigate(['/homepage'], {relativeTo: this.activatedRoute});
    }

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    //START - Google Analytics
    pushGAAuthView() {
        let productType = this.transactionInfo.getProduct().getProductType();
        let productOption = this.isNotNullOrUndefinedStr(productType) ?
                this.claimProductService.getProductDescription(productType, this.transactionInfo.getCatalogueCode()) : '';
        let policyNumber = this.claim.getPolicyNumber();

        if (this.transactionInfo.isMotorProduct()) {
            this.pushGAAuthMotorView(policyNumber);
        } else {
            (<any>window).dataLayer = (<any>window).dataLayer || [];
            (<any>window).dataLayer.push({
                'pageStep': 'Authentication',
                'vPath': '/authentication',
                'event': 'vpageview',
                'PolicyNo': policyNumber,
                'ecommerce': {
                    'checkout': {
                        'actionField': {'step': 2, 'option' : productOption}
                    }
                }
            });
        }
    }

    pushGAAuthMotorView(policyNumber: string) {
        let lodgedBy: string = "Customer";
        let claimMotor = this.claim.getClaimMotor();
        let eventCategory = "None";

        if (!UtilitiesService.isNullOrUndefined(claimMotor) && !claimMotor.getIsLodgeByPolicyHolder()) {
            lodgedBy = "Repairer";
            eventCategory = claimMotor.getRepairerName();
        }

        if(this.transactionInfo.getOrigin() && this.transactionInfo.getOrigin().startsWith("Qnect")) {
            lodgedBy = "QnectLodge";
        }
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': 'Authentication',
            'vPath': '/authentication',
            'event': 'vpageview',
            'PolicyNo': policyNumber,
            'dimension7': eventCategory,
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 2, 'option': lodgedBy}, //Step2-Authentication
                }
            }
        });
    }
    //END - Google Analytics
}